<template>
  <footer class="text-sm fixed bottom-0 h-10 z-50 bg-light dark:bg-dark text-black dark:text-white bg-opacity-80 transition-opacity w-full px-4 py-2 flex justify-between items-center">

      <div class="flex justify-start flex-1">
        <!--<div v-if="loading" class="w-10">
          <Loader />
        </div>-->

        <button
          v-if="showConnectToPlay"
          @click.stop="$emit('connect-clicked')"
          class="flex-shrink-0 cursor-pointer link text-center shrink-0">
          Connect to play
        </button>

        <button
          class="flex-shrink-0 cursor-pointer"
          href="#"
          @click.stop="$emit('auth-apple-music')">
          <span v-if="appleMusicConnected"
                class="text-green-400 border-transparent border-b-2 hover:border-green-400">
            Apple Music connected
          </span>
        </button>

        <button
          class="flex-shrink-0 cursor-pointer"
          @click.stop="$emit('auth-spotify')">
          <span v-if="spotifyConnected"
                class="text-green-400 border-transparent border-b-2 hover:border-green-400">Spotify connected</span>
        </button>

        <button
          class="flex-shrink-0 cursor-pointer"
          @click.stop="$emit('auth-tidal')">
          <span v-if="tidalConnected"
                class="text-green-400 border-transparent border-b-2 hover:border-green-400">TIDAL connected</span>
        </button>

      </div>
      <div class="flex-1 text-center">

      </div>
      <div class="flex-1 inline text-right">
        <NowPlayingItem
          @play-clicked="$emit('play-clicked')"
          @pause-clicked="$emit('pause-clicked')"
          @resume-clicked="$emit('resume-clicked')" />
      </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex'
import { isMobile, isAndroid, isIOS, isSafari } from 'mobile-device-detect'
import NowPlayingItem from '@/components/NowPlayingItem'

export default {
  name: "Footer",
  components: {
    NowPlayingItem
  },
  watch: {
    connected(val, prevVal){
      if(!prevVal && val){
        this.$emit('connected')
      }
    }
  },
  computed: {
    ...mapState('album', {
      album: 'album'
    }),
    connected() {
      return this.appleMusicConnected || this.tidalConnected || this.spotifyConnected
    },
    appleMusicConnected(){
      return this.$store.getters['musickit/connected']
    },
    tidalConnected(){
      return this.$store.getters['tidal/connected']
    },
    spotifyConnected(){
      return this.$store.getters['spotify/connected']
    },
    showConnectToPlay(){
      return !this.connected && this.album && !this.album.is_nft
    }
  },
  data(){
    return {
    }
  },
};
</script>