<template>
   <div>
      <button
        :type="type"
        @click="$emit('click')"
        :disabled="loading || disabled"
        class="text-base text-black bg-gray-300 rounded-lg hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black transition-colors focus:outline-none w-40 h-8 shadow-grid"
        :class="{'cursor-not-allowed': loading || disabled, 'hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black': !loading && !disabled}">
        <span v-if="!loading">{{ title }}</span>
        <span v-else>
          <Loader></Loader>
        </span>
      </button>
    </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'submit'
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
}
</script>
