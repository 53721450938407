<template>
  <button
    :disabled="loading"
    class="h-full focus:outline-none border-b-2 border-transparent"
    :class="{'cursor-not-allowed': loading, 'sm:hover:border-black sm:dark:hover:border-white': !loading}"
    @click="submit">
    <div v-if="!loading">
      <span v-if="!inCollection">
        <span>☆</span> <span class="hidden sm:inline-block">Add to collection</span>
      </span>
      <span v-else>
        <span>★</span> <span class="hidden sm:inline-block"> In your collection</span>
      </span>
    </div>
    <div v-else class="h-4 flex items-center">
      <Loader></Loader>
    </div>
  </button>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'AddToLibrary',
  props: {
    album: Object
  },
  data(){
    return{
      loading: false,
    }
  },
  computed: {
    ...mapState('collection', ['collection']),
    ...mapGetters('auth', ['loggedIn']),
    inCollection(){
      return this.collection.find(a => a.id === this.album.id)
    }
  },
  methods: {
    submit(){
      if(this.loading)return;

      if(!this.loggedIn){
        this.$emit('signup-login-clicked')
        return;
      }

      if(this.inCollection){
        this.$emit('remove-from-library')
      }else{
        this.add()
      }
    },
    add(){
      this.loading = true
      this.$store.dispatch('collection/add', this.album.id)
      .then(() => {
        this.showAddedModal = true
        setTimeout(() => {
          this.showAddedModal = false
        }, 1500)
      }).catch((error) => {
        console.log(error)
        if(error.response.status === 401){
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("user");
          this.$store.commit('auth/DELETE_TOKEN')
          this.$store.commit('auth/DELETE_USER')
          this.$router.push({ name: 'login'})
        }
      })
      .finally(() => {
        this.loading = false
      })
    },
  }
}
</script>
