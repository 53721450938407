<template>
  <div>
    <div class="mx-4 mt-16">
    <h1 class="text-2xl xs:text-3xl mb-10">Log in</h1>
      <form
        @submit.prevent="submit"
        class="text-base xs:text-xl">
        <div class="mb-10 flex flex-wrap">
          <div class="w-full flex justify-between max-w-md">
            <label class="mr-2 w-20 xs:w-24">Email</label>
            <input
              v-model="email"
              type="text"
              required
              class="flex-grow bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
            </div>
          <div
            v-if="errors.email"
            class="text-sm xs:text-lg text-red-500 w-full max-w-md">
            {{ errors.email.join(',') }}
          </div>
        </div>

        <div class="mb-4 flex flex-wrap relative">
          <div class="w-full flex justify-between max-w-md">
            <label class="mr-2 w-20 xs:w-24">Password</label>
            <input
              v-model="password"
              type="password"
              required
              class="flex-grow bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
          </div>
          <div
            v-if="errors.password"
            class="text-sm xs:text-lg text-red-500 w-full max-w-md">
            {{ errors.password.join(',') }}
          </div>
        </div>

        <div class="text-sm xs:text-lg mb-10 flex flex-wrap relative">
          <div class="w-full flex justify-end max-w-md">
            <router-link
              :to="{ name: 'forgotten-password' }"
              class="border-b-2 border-transparent hover:border-black dark:hover:border-white">Forgot</router-link>
          </div>
        </div>

        <div>
          <button
            type="submit"
            :disabled="loading"
            class="button w-64 h-12 mb-4"
            :class="{'cursor-not-allowed': loading, 'hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black': !loading}">
            <span v-if="!loading">GO</span>
            <span v-else>
              <Loader></Loader>
            </span>
          </button>

          <div class="text-base xs:text-lg">Don't have an account? <router-link
            :to="{ name: 'signup' }"
            class="border-b-2 border-transparent hover:border-black dark:hover:border-white">Sign up</router-link>
          </div>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      errors: [],
      loading: false,
    }
  },
  methods: {
    submit() {
      this.loading = true;
      axios.post(`${process.env.VUE_APP_API_URL}/listener/login`, {
        email: this.email,
        password: this.password,
      })
      .then((response) => {
        const token = response.data.token
        const user = response.data.user
        this.$store.commit('auth/SET_TOKEN', token)
        this.$store.commit('auth/SET_USER', user)
        //this.$store.dispatch('collection/getUserCollection')

        let route = this.$route.query.redirect || { name: 'usercollection', params: { username: user.username } }
        this.$router.push(route)
      })
      .catch((error) => {
        this.errors = error.response.data.message
      })
      .finally(() => {
        this.loading = false
      })
    }
  },
  mounted() {
  },
  created() {
  }
};
</script>
