import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'

const cache = setupCache({
  maxAge: 15 * 60 * 1000
})

const api = axios.create({
  adapter: cache.adapter,
  cache: {
    exclude: {
      // Only exclude PUT, PATCH and DELETE methods from cache
      methods: ['put', 'patch', 'delete']
    }
  }
})

export function GetCollection(address){
  return api({
    url: `https://api.mintsongs.com/v3/tokens/collected_by/${address}?contract_address=0x2B5426A5B98a3E366230ebA9f95a24f09Ae4a584&per=12&page=1`,
    method: 'get',
  })
}