<template>
  <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center" @click.stop="$emit('close')">

    <div class="modal-container">

    <div class="flex flex-col px-8">
        <button
          @click="$emit('copy-link')"
          class="modal-link">Copy link</button>
        <router-link
          v-if="loggedIn"
          :to="{ name: 'usercollection', params: { username: this.user.username } }"
          class="modal-link">Go to my collection</router-link>
        <p
          class="modal-close"
          @click.stop="$emit('close')">Close</p>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'CollectionModal',
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['loggedIn']),
  },
}
</script>