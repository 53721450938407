import Vue from 'vue'
import Router from 'vue-router'
import store from "@/store";

import Callback from '@/views/Callback'
import Home from '@/views/Home'
import Release from '@/views/Release'
import UserCollection from '@/views/UserCollection'
import Login from '@/views/Login'
import Signup from '@/views/Signup'
import ForgottenPassword from '@/views/ForgottenPassword'
import ResetPassword from '@/views/ResetPassword'
import Spotify from '@/views/Spotify'
import Tidal from '@/views/Tidal'
import Profile from '@/views/Profile'
import About from '@/views/About'
import Start from '@/views/Start'
import PageNotFound from '@/views/PageNotFound'
import Wallet from '@/views/Wallet'

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/tidal',
      name: 'tidal',
      component: Tidal,
    },
    {
      path: '/spotify',
      name: 'spotify',
      component: Spotify,
    },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/start',
      name: 'start',
      component: Start,
    },
    {
      path: '/forgotten-password',
      name: 'forgotten-password',
      component: ForgottenPassword,
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/album/blackmidicavalcade',
      name: 'blackmidicavalcade',
      component: Release,
    },
    {
      path: '/release/blackmidicavalcade',
      name: 'blackmidicavalcade',
      component: Release,
    },
    {
      path: '/albums/:id',
      redirect: { name: 'release' }
    },
    {
      path: '/album/:id',
      redirect: { name: 'release' }
    },
    {
      path: '/release/:id',
      name: 'release',
      component: Release,
    },
    {
      path: '/animate/:id',
      name: 'animate',
      component: Release,
    },
    {
      path: '/callback/:service',
      name: 'callback',
      component: Callback,
    },
    {
      path: '/@:username',
      name: 'usercollection',
      component: UserCollection
    },
    {
      path: '/wallet/:wallet',
      name: 'wallet',
      component: Wallet,
    },
    {
      path: '*',
      name: 'not-found',
      component: PageNotFound
    }
  ]
});

router.beforeEach((to, from, next) => {

  if(to.name !== 'album' && to.name !== 'release' && to.name !== 'callback'){
    store.dispatch('album/clearAlbum')
  }

  if (to.matched.some((record) => record.meta.requiresVisitor)) {

    if (store.getters['auth/loggedIn']) {

      next({ name: 'home' });

    } else {

      next();

    }

  }else if (to.matched.some((record) => record.meta.requiresAuth)) {

    if (store.getters['auth/loggedIn']) {

      next();

    } else {

      next({ name: 'login' });

    }

  }

  next();

});

export default router;
