export function apiHeaders () {
  return new Headers({
    Authorization: 'Bearer ' + MusicKit.getInstance().developerToken,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Music-User-Token': '' + MusicKit.getInstance().musicUserToken
  });
}

const constants = {
  player_name: process.env.VUE_APP_APP_NAME,
  build: '1',
  icon: 'https://player.sleevenote.com/icon.png',
  developer_token: process.env.VUE_APP_APPLE_MUSIC_DEV_TOKEN
};

const state = {
  isInitialized: false,
  storefront: '',
  isAuthorized: false,
  playbackState: 0,
  nowPlayingItem: null,
  playbackStates: []
};

const getters = {
  connected(state) {
    return state.isAuthorized
  },
  storefront(state) {
    return state.storefront;
  }
};

const mutations = {
  SET_INITIALIZED(state) {
    if (state.isInitialized) {
      console.warn('Already initialized; aborting')
      return
    }
    state.isInitialized = true
  },
  SET_IS_AUTHORIZED(state, isAuthorized) {
    state.isAuthorized = isAuthorized
  },
  SET_STOREFRONT(state, storefront) {
    state.storefront = storefront
  },
  SET_PLAYBACK_STATE(state, playbackState) {
    state.playbackState = playbackState
  },
  SET_NOW_PLAYING_ITEM(state, item) {
    state.nowPlayingItem = item
  },
  SET_PLAYBACK_STATES(state, playbackStates){
    state.playbackStates = playbackStates
  }
};

const actions = {
  async init ({ commit, dispatch }) {
    let instance = await MusicKit.configure({
      developerToken: constants.developer_token,
      app: {
        name: constants.player_name,
        build: constants.build,
        icon: constants.icon
      }
    });

    // storefront
    commit('SET_STOREFRONT', instance.storefrontId);
    commit('SET_PLAYBACK_STATES', MusicKit.PlaybackStates);
    console.log(MusicKit.PlaybackStates)

    // Update authorization status
    commit('SET_IS_AUTHORIZED', instance.isAuthorized);
    if(instance.isAuthorized){
      //dispatch('services/setAppleMusic', null, { root: true });
    }

    // Update playback state
    commit('SET_PLAYBACK_STATE', instance.playbackState);

    instance.addEventListener(MusicKit.Events.playbackStateDidChange, (evt) => {
      commit('SET_PLAYBACK_STATE', evt.state)
      if(evt.state !== MusicKit.PlaybackStates.loading &&
        evt.state !== MusicKit.PlaybackStates.paused){
        commit('album/IS_PLAYING', true, { root: true})
      }else{
        commit('album/IS_PLAYING', false, { root: true})
      }
    })

    instance.addEventListener(MusicKit.Events.storefrontIdentifierDidChange, () => {
      commit('SET_STOREFRONT', instance.storefrontId);
    })

    instance.addEventListener(MusicKit.Events.storefrontCountryCodeDidChange, () => {
      commit('SET_STOREFRONT', instance.storefrontId);
    })

    instance.addEventListener(MusicKit.Events.authorizationStatusDidChange, () => {
      /*if (instance.isAuthorized) {
        setTimeout(() => {
          MusicKit.getInstance().me().then((me) => {
            commit('SET_STOREFRONT', me.storefront);
          });
        }, 1000);
      }*/
      commit('SET_IS_AUTHORIZED', instance.isAuthorized);
    })

    instance.addEventListener(MusicKit.Events.mediaItemDidChange, (evt) => {
      setTimeout(() => {
        commit('SET_NOW_PLAYING_ITEM', evt.item);
      }, 1000);
    })

    instance.addEventListener(MusicKit.Events.mediaPlaybackError, (evt) => {
      console.error('PLAYBACK_ERROR', evt);
      //alert('Error playing back album');
    })

    // Initialize the instance
    commit('SET_INITIALIZED');
  },
  authorize() {
    let instance = MusicKit.getInstance();
    instance.authorize()
  },
  unauthorize({ dispatch }) {
    let instance = MusicKit.getInstance()
    instance.unauthorize()
    //dispatch('services/setNoService', null, { root: true })
  },
  play({ dispatch }, payload) {
    //console.log('play', payload)
    //dispatch('services/setLoading', true, { root: true })
    let instance = MusicKit.getInstance();
    if(payload){
      instance.setQueue({ ['album']: payload.id }).then(() => {
        if(payload.index && payload.index > 0) {
          instance.changeToMediaAtIndex(payload.index).then(() => {
            instance.play();
          });
        }else{
          instance.play();
        }
      }).catch((err) => {
        console.log(err)
        alert('Album unavailable on Apple Music in your country, sorry!')
      });
    }else{
      instance.play();
    }

  },
  pause () {
    let instance = MusicKit.getInstance();
    return instance.pause();
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
