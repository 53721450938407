<template>
  <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center" @click.stop="$emit('close')">

    <div class="modal-container">

    <div class="flex flex-col px-8">

        <div
          v-if="loggedIn"
          class="flex flex-col mb-8">
          <div class="modal-text">@{{ user.username || truncatedWallet }}</div>

          <router-link :to="{ name: 'profile' }" class="modal-link">Edit your profile</router-link>

          <button
            :disabled="loading"
            @click="logout"
            class="modal-link truncate max-w-full"
            :class="{'cursor-not-allowed': loading, 'hover:border-white': !loading}">
            <span v-if="!loading">Logout</span>
            <div
              v-else
              class="w-6">
              <Loader></Loader>
            </div>
          </button>
        </div>
        <div
          v-else
          class="flex flex-col mb-8">
          <router-link
            :to="loginLink"
            class="modal-link">Log in to your collection</router-link>
          <router-link
            :to="signupLink"
            class="modal-link">(or sign up)</router-link>
        </div>

        <div class="flex flex-col mb-8">
          <a href="https://sleevenote.notion.site/sleevenote/Sleevenote-6503a8c8d3b2421b8451dad4ec777eb6" target="_blank" class="modal-link">About Sleevenote</a>

          <a href="https://create.sleevenote.com" target="_blank" class="modal-link">Label or artist? Create a release</a>
        </div>

        <div class="flex flex-col mb-8">
          <a
            href="https://discord.com/invite/pAgXWU2RdH"
            target="_blank"
            class="modal-link mb-8">Chat with us on Discord
          </a>

          <a
            href="mailto:support@sleevenote.com"
            class="modal-link">Email feedback
          </a>
        </div>

        <button
          @click.stop="$emit('close')"
          class="modal-close">
          Close
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'LogoutModal',
  data(){
    return{
      loading: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    truncatedWallet(){
      return this.user.wallet.substring(0, 6) + '...'
    },
    ...mapGetters('auth', {
      loggedIn: 'loggedIn',
    }),
    signupLink(){
      let link = {
        name: 'signup',
      }

      if(this.$route.name === 'release' || this.$route.name === 'album'){
        link.query = { redirect: this.$route.fullPath }
      }

      return link
    },
    loginLink(){
      let link = {
        name: 'login',
      }

      if(this.$route.name === 'release' || this.$route.name === 'album'){
        link.query = { redirect: this.$route.fullPath }
      }

      return link
    },
  },
  methods: {
    logout(){
      if(this.loading)return;

      this.loading = true
      axios.post(`${process.env.VUE_APP_API_URL}/logout`, {}, {
        headers: {
          Authorization: "Bearer " + this.$store.state.auth.token
        }
      })
      .then((response) => {
        console.log(response)
        this.$router.replace({ name: 'home' })

      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false
        window.localStorage.removeItem("token")
        window.localStorage.removeItem("user")
        this.$store.commit('auth/DELETE_TOKEN')
        this.$store.commit('auth/DELETE_USER')
        this.$store.commit('collection/SET_COLLECTION', [])
        this.$store.dispatch('web3/disconnect')
      })
    },
  }
}
</script>