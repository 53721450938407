<template>
  <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center" @click.stop="$emit('close')">

    <div class="modal-container">

    <div class="flex flex-col px-8">
        <button
          @click="$emit('copy-link')"
          class="modal-link">Copy link</button>
        <button
          @click.stop="$emit('remove')"
          class="modal-link">Remove from collection</button>
        <p
          class="modal-close"
          @click.stop="$emit('close')">Close</p>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'RemoveFromCollectionModal',
  props: {
    album: null
  },
}
</script>