import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'

const cache = setupCache({
  maxAge: 15 * 60 * 1000
})

const api = axios.create({
  adapter: cache.adapter,
  cache: {
    exclude: {
      // Only exclude PUT, PATCH and DELETE methods from cache
      methods: ['put', 'patch', 'delete']
    }
  }
})

export function GetCollectionByUserId(address){
  return api({
    url: 'https://indexer-prod-mainnet.zora.co/v1/graphql',
    method: 'post',
    data: {
      query: `
      query GetCollectionByUserId($userId: String!, $tokenContracts: [String!] !) {
        Token(where: {
          _and: [{
            tokenContract: {
              address: {
                _in: $tokenContracts
              }
            }
          }, {
            minter: {
              _nilike: $userId
            }
          }, {
            _or: [{
              _and: [{
                media: {
                  metadata: {
                    json: {
                      _has_keys_all: ["body", "origin"]
                    }
                  }
                }
              }, {
                _or: [{
                  owner: {
                    _ilike: $userId
                  }
                }, {
                  currentAuction: {
                    tokenOwner: {
                      _ilike: $userId
                    }
                  }
                }]
              }]
            }, {
              _or: [{
                owner: {
                  _ilike: $userId
                }
              }, {
                currentAuction: {
                  tokenOwner: {
                    _ilike: $userId
                  }
                }
              }]
            }]
          }]
        }) {
          ...IndexerTokenFragment
        }
      }
      fragment IndexerTokenFragment on Token {
        address metadata {
          ...MetadataFragment
        }
        media {
          ...IndexerMediaFragment
        }
        owner minter tokenContract {
          ...TokenContractFragment
        }
        tokenId v3Ask {
          ...V3AskFragment
        }
        transferEvents(order_by: {
          blockNumber: desc
        }) {
          ...TransferEventFragment
        }
        auctions(order_by: {
          createdEvent: {
            blockNumber: desc
          }
        }) {
          ...IndexerAuctionFragment
        }
        currentAuction {
          ...IndexerAuctionFragment
        }
        mintTransferEventId mintTransferEvent {
          blockNumber
        }
        v3Events(order_by: {
          blockNumber: desc_nulls_last
        }) {
          ...V3Event
        }
      }
      fragment V3AskFragment on V3Ask {
        id tokenId askPrice askCurrency sellerFundsRecipient findersFeeBps finder tokenContract status
      }
      fragment MetadataFragment on TokenMetadata {
        address createdAt id json tokenURI
      }
      fragment TokenContractFragment on TokenContract {
        address deployedAtBlockNumber name supportsMetadata symbol
      }
      fragment IndexerMediaFragment on Media {
        askEvents(order_by: {
          blockNumber: desc
        }) {
          ...AskEventFragment
        }
        auctions(order_by: {
          createdEvent: {
            blockNumber: desc
          }
        }) {
          ...IndexerAuctionFragment
        }
        bidEvents(order_by: {
          blockNumber: desc
        }) {
          ...OfferEventFragment
        }
        asks {
          address amount currencyAddress status tokenId
        }
        bids {
          bidEvents(where: {
              status: {
                _eq: "CREATED"
              }
            }
            order_by: {
              blockNumber: desc_nulls_last
            }
            limit: 1) {
            ...OfferEventFragment
          }
        }
        contentHash contentURI creator creatorBidShare metadata {
          json
        }
        metadataHash metadataURI mintTransferEventId owner ownerBidShare prevOwner prevOwnerBidShare token {
          mintTransferEvent {
            id blockNumber blockTimestamp
          }
          currentAuction {
            reservePrice lastBidder lastBidAmount status expiresAt currency {
              address decimals symbol
            }
          }
        }
        tokenId transferEvents(order_by: {
          blockNumber: desc
        }) {
          ...TransferEventFragment
        }
      }
      fragment AskEventFragment on MarketAskEvent {
        amount blockNumber blockTimestamp currency id status transactionHash
      }
      fragment IndexerAuctionFragment on Auction {
        amountTokenOwnerReceived approved auctionId bidEvents(order_by: {
          blockNumber: desc
        }) {
          ...BidEventFragment
        }
        canceledEvent {
          blockNumber blockTimestamp transactionHash transaction {
            from
          }
        }
        createdEvent {
          blockNumber blockTimestamp transactionHash curator tokenOwner
        }
        approvalEvents {
          blockNumber blockTimestamp
        }
        endedEvent {
          blockNumber blockTimestamp winner auctionId
        }
        curator curatorFee curatorFeePercentage currency {
          address decimals name symbol
        }
        duration expiresAt firstBidTime lastBidAmount lastBidder prevOwnerBidShareBeforeAuctionCreated reservePrice status tokenId tokenOwner winner
      }
      fragment BidEventFragment on AuctionBidEvent {
        address auctionId blockNumber blockTimestamp extended firstBid id sender value auction {
          currency {
            decimals symbol
          }
        }
      }
      fragment OfferEventFragment on MarketBidEvent {
        amount bidder blockNumber blockTimestamp currency {
          address decimals name symbol
        }
        id recipient sellOnShare status tokenId transactionHash
      }
      fragment TransferEventFragment on TokenTransferEvent {
        id blockTimestamp blockNumber to from
      }
      fragment V3Event on Event {
        id eventType blockNumber blockTimestamp details
      }`,
      variables: {
        tokenContracts: [
          "0x0bC2A24ce568DAd89691116d5B34DEB6C203F342",
          "0xabEFBc9fD2F806065b4f3C237d4b59D9A97Bcac7"
        ],
        userId: address.toLowerCase()
      }
    }
  })
}

export function GetTracksByNFTIDs(nftIds){
  return api({
    url: 'https://catalog-prod.hasura.app/v1/graphql',
    method: 'post',
    data: {
      query: `
      query GetTracksByNFTIDs($nftIds: [String!]!) {
        tracks(where: {nft_id: {_in: $nftIds}}, order_by: {nft_id: desc}) {
          id
          title
          description
          nft_id
          ipfs_hash_audio
          ipfs_hash_lossy_audio
          ipfs_hash_lossy_artwork
          ipfs_hash_artwork
          ipfs_hash_metadata
          nft_content_hash
          nft_metadata_hash
          artist_fee
          created_at
          duration
          price
          artist {
            id
            name
            handle
          }
          track_number
          project_title
          project_notes
          short_url
          mime_type
          contract_address
        }
      }`,
      variables: {
        nftIds: nftIds
      }
    }
  })
}

export function GetTokenById(contractAddress, id) {
  return api({
    url: 'https://indexer-prod-mainnet.hasura.app/v1/graphql',
    method: 'post',
    data: {
      query: `
      query GetTokenById($tokenId: String!, $tokenContractAddress: String!) {
        Token(
          where: {_and: {address: {_eq: $tokenContractAddress}, tokenId: {_eq: $tokenId}}}
        ) {
          ...IndexerTokenFragment
        }
      }

      fragment IndexerTokenFragment on Token {
        address
        metadata {
          ...MetadataFragment
        }
        media {
          ...IndexerMediaFragment
        }
        owner
        minter
        tokenContract {
          ...TokenContractFragment
        }
        tokenId
        v3Ask {
          ...V3AskFragment
        }
        transferEvents(order_by: {blockNumber: desc}) {
          ...TransferEventFragment
        }
        auctions(order_by: {createdEvent: {blockNumber: desc}}) {
          ...IndexerAuctionFragment
        }
        currentAuction {
          ...IndexerAuctionFragment
        }
        mintTransferEventId
        mintTransferEvent {
          blockNumber
        }
        v3Events(order_by: {blockNumber: desc_nulls_last}) {
          ...V3Event
        }
        v3ReserveAuction {
          ...V3ReserveAuction
        }
      }

      fragment V3AskFragment on V3Ask {
        id
        tokenId
        askPrice
        askCurrency
        sellerFundsRecipient
        findersFeeBps
        finder
        tokenContract
        status
      }

      fragment MetadataFragment on TokenMetadata {
        address
        createdAt
        id
        json
        tokenURI
      }

      fragment TokenContractFragment on TokenContract {
        address
        deployedAtBlockNumber
        name
        supportsMetadata
        symbol
      }

      fragment IndexerMediaFragment on Media {
        askEvents(order_by: {blockNumber: desc}) {
          ...AskEventFragment
        }
        auctions(order_by: {createdEvent: {blockNumber: desc}}) {
          ...IndexerAuctionFragment
        }
        bidEvents(order_by: {blockNumber: desc}) {
          ...OfferEventFragment
        }
        asks {
          address
          amount
          currencyAddress
          status
          tokenId
        }
        bids {
          bidEvents(
            where: {status: {_eq: "CREATED"}}
            order_by: {blockNumber: desc_nulls_last}
            limit: 1
          ) {
            ...OfferEventFragment
          }
        }
        contentHash
        contentURI
        creator
        creatorBidShare
        metadata {
          json
        }
        metadataHash
        metadataURI
        mintTransferEventId
        owner
        ownerBidShare
        prevOwner
        prevOwnerBidShare
        token {
          mintTransferEvent {
            id
            blockNumber
            blockTimestamp
          }
          currentAuction {
            reservePrice
            lastBidder
            lastBidAmount
            status
            expiresAt
            currency {
              address
              decimals
              symbol
            }
          }
        }
        tokenId
        transferEvents(order_by: {blockNumber: desc}) {
          ...TransferEventFragment
        }
      }

      fragment AskEventFragment on MarketAskEvent {
        amount
        blockNumber
        blockTimestamp
        currency
        id
        status
        transactionHash
      }

      fragment IndexerAuctionFragment on Auction {
        amountTokenOwnerReceived
        approved
        auctionId
        bidEvents(order_by: {blockNumber: desc}) {
          ...BidEventFragment
        }
        canceledEvent {
          blockNumber
          blockTimestamp
          transactionHash
          transaction {
            from
          }
        }
        createdEvent {
          blockNumber
          blockTimestamp
          transactionHash
          curator
          tokenOwner
        }
        approvalEvents {
          blockNumber
          blockTimestamp
        }
        endedEvent {
          blockNumber
          blockTimestamp
          winner
          auctionId
        }
        curator
        curatorFee
        curatorFeePercentage
        currency {
          address
          decimals
          name
          symbol
        }
        duration
        expiresAt
        firstBidTime
        lastBidAmount
        lastBidder
        prevOwnerBidShareBeforeAuctionCreated
        reservePrice
        status
        tokenId
        tokenOwner
        winner
      }

      fragment BidEventFragment on AuctionBidEvent {
        address
        auctionId
        blockNumber
        blockTimestamp
        extended
        firstBid
        id
        sender
        value
        auction {
          currency {
            decimals
            symbol
          }
        }
      }

      fragment OfferEventFragment on MarketBidEvent {
        amount
        bidder
        blockNumber
        blockTimestamp
        currency {
          address
          decimals
          name
          symbol
        }
        id
        recipient
        sellOnShare
        status
        tokenId
        transactionHash
      }

      fragment TransferEventFragment on TokenTransferEvent {
        id
        blockTimestamp
        blockNumber
        to
        from
      }

      fragment V3Event on Event {
        id
        eventType
        blockNumber
        blockTimestamp
        details
        parentEntityId
      }

      fragment V3ReserveAuction on V3ReserveAuction {
        duration
        highestBid
        highestBidder
        winner
        expiresAt
        status
        reservePrice
        listingFeeBps
        listingFeeRecipient
        sellerFundsRecipient
        ownerBidShareBeforeAuctionCreated
        prevOwnerBidShareBeforeAuctionCreated
        creatorBidShareBeforeAuctionCreated
        currency
      }`,
      variables: {
        tokenContractAddress: contractAddress,
        tokenId: id
      }
    }
  })
}

export function GetOwner(id){
  return api({
    url: 'https://api.thegraph.com/subgraphs/name/ourzora/zora-v1',
    method: 'post',
    data: {
      query: `
      query GetNFTByZoraID($zoraId: ID!) {
        media(id: $zoraId) {  ...MediaFragment  }
      }
      fragment MediaFragment on Media {  id  creator {
        id
      }  owner {
          id
      }}`,
      variables: {
        zoraId: id
      }
    }
  })
}

export function GetUserHandle(id){
  return api({
    url: 'https://catalog-prod.hasura.app/v1/graphql',
    method: 'post',
    data: {
      query: `
      query GetUsersFromAddresses($addresses: [String!]!) {
        catalog_users(where: {id: {_in: $addresses}}) {
          id
          handle
          name
          picture_uri
        }
      }`,
      variables: {
        addresses: id
      }
    }
  })
}