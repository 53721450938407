import { ethers } from 'ethers'
import WalletConnect from '@walletconnect/client'
import QRCodeModal from '@walletconnect/qrcode-modal'
import { getLocal, setLocal, removeLocal } from "@/utils/local"

const CACHED_PROVIDER_KEY = 'WEB3_CONNECT_CACHED_PROVIDER'
const CACHED_DEEPLINK_KEY = 'WALLETCONNECT_DEEPLINK_CHOICE'

const state = {
  cachedProvider: getLocal(CACHED_PROVIDER_KEY) || null,
  address: null,
  chainId: null,
  ens: null,
  connector: null,
  connected: false
}

const getters = {

}

const mutations = {
  cachedProvider (state, value) {
    state.cachedProvider = value
    setLocal(CACHED_PROVIDER_KEY, value)
  },
  address (state, value) {
    state.address = value
  },
  chainId (state, value) {
    state.chainId = value
  },
  ens (state, value) {
    state.ens = value
  },
  connector (state, value) {
    state.connector = value
  },
  connected (state, value) {
    state.connected = value
  },
}

const actions = {
  setCachedProvider({ commit }, id) {
    commit('cachedProvider', id)
  },
  async connectInjected({ commit }){
    console.log('connectInjected')
    let instance = null;
    if (typeof window.ethereum !== 'undefined') {
      instance = window.ethereum;
      try {
        await instance.request({ method: 'eth_requestAccounts' })
      } catch (error) {
        throw new Error("User Rejected");
      }
    } else if (window.web3) {
      instance = window.web3.currentProvider;
    } else if (window.celo) {
      instance = window.celo;
    } else {
      throw new Error("No Web3 Provider found");
    }

    const provider = new ethers.providers.Web3Provider(instance)
    const signer = provider.getSigner()
    const address = await signer.getAddress()
    commit('cachedProvider', 'injected')
    commit('address', address)
    commit('connected', true)
  },
  async connectWalletConnect({ commit, state, dispatch }){
    console.log('connectWalletConnect')
    // Create a connector
    const connector = new WalletConnect({
      bridge: "https://bridge.walletconnect.org", // Required
      qrcodeModal: QRCodeModal,
    });

    //commit('connector', connector)

    // Check if connection is already established
    if (!connector.connected) {
      console.log('not connected, create new session')
      // create new session
      await connector.createSession();
    }else{
      console.log('already connected', state.cachedProvider)
      const { accounts } = getLocal(state.cachedProvider);
      commit('cachedProvider', 'walletconnect')
      commit('address', accounts[0])
      commit('connected', true)
    }

    dispatch('subscribeToEvents', connector)
  },
  subscribeToEvents({ commit, state, dispatch }, connector){

    //const connector = state.connector
    // Subscribe to connection events
    connector.on("connect", (error, payload) => {
      console.log('connect', error, payload)
      if (error) {
        throw error;
      }

      // Get provided accounts and chainId
      const { accounts, chainId } = payload.params[0];
      commit('cachedProvider', 'walletconnect')
      commit('address', accounts[0])
      commit('chainId', chainId)
      commit('connected', true)
      //dispatch('getEns')
    });

    connector.on("session_update", (error, payload) => {
      console.log('session_update', error, payload)
      if (error) {
        throw error;
      }

      // Get updated accounts and chainId
    //  const { accounts, chainId } = payload.params[0];
    });

    connector.on("disconnect", (error, payload) => {
      console.log('disconnect', error, payload)
      if (error) {
        throw error;
      }

      dispatch('disconnect')
    });
  },
  disconnect({ commit, state }){
    commit('cachedProvider', null)
    commit('address', null)
    commit('chainId', null)
    commit('connected', false)
    console.log(state.cachedProvider)
    removeLocal('walletconnect')
    removeLocal('injected')
    removeLocal(CACHED_PROVIDER_KEY)
    //removeLocal(CACHED_DEEPLINK_KEY);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};