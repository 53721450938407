import Vue from 'vue'
import Vuex from 'vuex'

import musickit from './modules/musicKit'
import spotify from './modules/spotify'
import tidal from './modules/tidal'
import album from './modules/album'
import auth from './modules/auth'
import collection from './modules/collection'
//import search from './modules/search'
import web3 from './modules/web3/eth/index'


// Initialize Vue extensions
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    musickit,
    tidal,
    spotify,
    album,
    auth,
    collection,
    //search,
    web3,
  },
  strict: true
});