<template>
  <div class="w-full flex justify-end text-sm opacity-80">
    <input
      type="checkbox"
      v-model="enabled"
      :id="id"
      class="invisible" />
    <label :for="id" class="px-2 py-1 bg-gray-300 hover:bg-gray-400 text-light dark:text-dark rounded-md cursor-pointer select-none"
      :class="{ 'bg-mint': enabled}">
      <span v-if="enabled">Done</span>
      <span v-else>{{ title }}</span>
    </label>
  </div>
</template>

<script>

export default {
  name: 'ReorderButton',
  props: {
    id: {
      type: String,
      default() {
        return `checkbox-${this._uid}`
      },
    },
    title: String,
    value: Boolean
  },
  computed: {
    enabled: {
      get() {
        return this.value
      },
      set(value){
        this.$emit('input', value)
      }
    }
  }
}
</script>
