<template>
  <div>
    <div class="mx-4 mt-16">
      <h1 class="text-2xl xs:text-3xl mb-10">Reset password</h1>
      <form @submit.prevent="submit" class="text-base xs:text-xl">

        <div class="mb-10 flex flex-wrap">
          <div class="w-full flex justify-between max-w-md">
            <label class="mr-2 w-28 xs:w-36">Confirm email</label>
            <input
              v-model="email"
              type="text"
              required
              class="flex-grow bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
            </div>
          <div
            v-if="errors.email"
            class="text-sm xs:text-lg text-red-500 w-full max-w-md">
            {{ errors.email.join(',') }}
          </div>
        </div>

        <div class="mb-10 flex flex-wrap">
          <div class="w-full flex justify-between max-w-md">
            <label class="mr-2 w-28 xs:w-36">Password</label>
            <input
              v-model="password"
              type="password"
              required
              class="flex-grow bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
            </div>
          <div
            v-if="errors.password"
            class="text-sm xs:text-lg text-red-500 w-full max-w-md">
            {{ errors.password.join(',') }}
          </div>
        </div>

        <div>
          <button
            type="submit"
            :disabled="loading"
            class="button w-64 h-12 mb-4"
            :class="{'cursor-not-allowed': loading, 'hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black': !loading}">
            <span v-if="!loading">GO</span>
            <span v-else>
              <Loader></Loader>
            </span>
          </button>
        </div>

        <div v-if="!loading && passwordReset" class="text-sm xs:text-lg text-green-500 w-full max-w-md">
          Success! Password set. You may now <router-link :to="{ name: 'login' }" class="border-b-2 border-green-500 hover:border-transparent">log in</router-link> with your new password.
        </div>
        <div
          v-else-if="errors.length > 0"
          class="text-sm xs:text-lg text-red-500 w-full max-w-md">
          {{ errors.join(',') }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ForgottenPassword',
  data(){
    return{
      email: null,
      password: null,
      token: null,
      errors: {},
      loading: false,
      passwordReset: false
    }
  },
  methods: {
    submit(){
      this.loading = true;
      axios.post(`${process.env.VUE_APP_API_URL}/listener/reset-password`, {
        email: this.email,
        password: this.password,
        token: this.token
      })
      .then((response) => {
        this.passwordReset = true
        this.errors = {}
      })
      .catch((error) => {
        this.errors = error.response.data.errors
        this.passwordReset = false
      })
      .finally(() => {
        this.loading = false
      })
    }
  },
  mounted(){
    this.token = this.$route.query.token;
  },
  created() {
  }
};
</script>
