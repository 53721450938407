export const setLocal = (key, data) => {
  const jsonData = JSON.stringify(data);
  if (window.localStorage) {
    window.localStorage.setItem(key, jsonData);
  }
};

export const getLocal = (key) => {
  let data = null;
  let raw = null;
  if (window.localStorage) {
    raw = window.localStorage.getItem(key);
  }
  if (raw && typeof raw === "string") {
    try {
      data = JSON.parse(raw);
    } catch (error) {
      return null;
    }
  }
  return data;
};

export const removeLocal = (key) => {
  if (window.localStorage) {
    window.localStorage.removeItem(key);
  }
};

export const updateLocal = (key, data) => {
  const localData = getLocal(key) || {};
  const mergedData = { ...localData, ...data };
  setLocal(key, mergedData);
};