<template>
  <div class="login"></div>
</template>

<script>

export default {
  name: 'Callback',
  methods: {
    authSpotify(){
      const { code } = this.$route.query;
      this.$store.dispatch('spotify/getAccessToken', code)
      this.redirect()
    },
    authTidal(){
      const { code } = this.$route.query;
      /*if (window.opener) {
        window.opener.postMessage(code)
        window.close()
      }*/
      this.$store.dispatch('tidal/getAccessToken', code)
      this.redirect()
    },
    redirect(){
      if(this.$store.state.album.selectedAlbumId !== null){
        this.$router.push(`/album/${this.$store.state.album.selectedAlbumId}`)
      }else{
        this.$router.push('/')
      }
    }
  },
  created() {
    let service = this.$route.params.service

    if(service == 'spotify'){
      this.authSpotify()
    }else if(service == 'tidal'){
      this.authTidal()
    }

  }
};
</script>
