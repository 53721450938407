<template>
  <div>
    <img class="fixed left-0 top-0 z-0 w-screen h-screen object-cover opacity-30" src="@/assets/about_bg_2.jpg" />
    <div class="relative z-1 mx-4 mt-16 mb-32 text-black dark:text-white font-extrabold max-w-screen-lg">
      <h1 class="mt-4 mb-6 text-3xl xs:text-5xl">This is Sleevenote</h1>
      <div class="py-2 mb-8 text-2xl xs:text-3xl">Wouldn't it be awesome if you could show off music you love and be able to share it with anyone regardless of whether they use Spotify, Apple Music or TIDAL?</div>
      <div class="py-2 mb-8 text-2xl xs:text-3xl">Wouldn't it be awesome to see additional album art - tracklist art, booklet art, lyrics and credits?</div>
      <div class="py-2 mb-8 text-2xl xs:text-3xl">Wouldn't it be awesome to add your Catalog and Sound NFTs to a collection of other music?</div>
      <div class="py-2 mb-8 text-2xl xs:text-3xl">This is Sleevenote and it's 3 x awesome</div>
      <div class="py-2 mb-8 text-2xl xs:text-3xl"><router-link :to="{ name: 'home' }" class="border-b-4 border-black dark:border-white hover:border-transparent dark:hover:border-transparent font-extrabold">Create your collection now</router-link></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
};
</script>
