import axios from 'axios'
import { generateRandomString, generateCodeChallenge } from '@/utils/random'
import { upperFirst } from 'lodash';

const constants = {
  code_verifier_name: 'tidal-code-verifier',
  access_token_name: 'tidal-access-token',
  refresh_token_name: 'tidal-refresh-token',
  session_name: 'tidal-session',
  player_name: process.env.VUE_APP_APP_NAME,
  redirect_uri: process.env.VUE_APP_TIDAL_REDIRECT_URI,
  client_id: process.env.VUE_APP_TIDAL_CLIENT_ID
};

const state = {
  redirect_uri: constants.redirect_uri,
  client_id: constants.client_id,
  code_verifier: localStorage.getItem(constants.code_verifier_name) || '',
  access_token: localStorage.getItem(constants.access_token_name) || '',
  refresh_token: localStorage.getItem(constants.refresh_token_name) || '',
  expires_at: null,
  autoplay: false,
  session: localStorage.getItem(constants.session_name) ? JSON.parse(localStorage.getItem(constants.session_name)) : '',
  currentIndex: 0,
  track: null,
  nextTrack: null,
  playing: false,
  busy: false,
  tracks: [],
  nowPlayingTracks: []
};

const getters = {
  connected(state) {
    return state.access_token ? true : false
  },
  accessToken(state) {
    return state.access_token || ''
  },
  refreshToken(state) {
    return state.refresh_token || ''
  },
  getTrack(state){
    console.log(state.tracks, state.currentIndex)
    return state.tracks[state.currentIndex]
  }
};

const mutations = {
  SET_ACCESS_TOKEN(state, access_token) {
    state.access_token = access_token
    localStorage.setItem(constants.access_token_name, access_token)
  },
  SET_REFRESH_TOKEN(state, refresh_token) {
    state.refresh_token = refresh_token
    localStorage.setItem(constants.refresh_token_name, refresh_token)
  },
  CLEAR_ALL_TOKENS(state) {
    state.access_token = ''
    state.refresh_token = ''
    state.code_verifier = ''
    state.device_id = ''
    localStorage.removeItem(constants.code_verifier_name)
    localStorage.removeItem(constants.access_token_name)
    localStorage.removeItem(constants.refresh_token_name)
    localStorage.removeItem(constants.device_id_name)
    localStorage.removeItem(constants.session_name)
  },
  SET_CODE_VERIFIER(state, code_verifier) {
    state.code_verifier = code_verifier
    localStorage.setItem(constants.code_verifier_name, code_verifier)
  },
  SET_EXPIRES_IN(state, expires_in) {
    state.expires_in = expires_in
  },
  SET_SESSION(state, session) {
    state.session = session
    localStorage.setItem(constants.session_name, JSON.stringify(session))
  },
  SET_TRACKS(state, tracks){
    state.tracks = tracks
  },
  CONCAT_TRACKS(state, tracks){
    state.tracks = state.tracks.concat(tracks)
  },
  CLEAR_TRACKS(state){
    state.tracks = []
  },
  SET_TRACK(state, track){
    state.track = track
    state.nextTrack = null
  },
  SET_NEXT_TRACK(state, track){
    state.nextTrack = track
  },
  SET_BUSY(state, busy){
    state.busy = busy
  },
  SET_CURRENT_INDEX(state, index){
    state.currentIndex = index
  },
  INCREMENT_CURRENT_INDEX(state){
    state.currentIndex = state.currentIndex + 1
  },
  SET_IS_PLAYING(state, playing){
    state.playing = playing
  },
  SET_NOW_PLAYING_TRACKS(state){
    state.nowPlayingTracks = JSON.parse(JSON.stringify(state.tracks))
  }
};

const actions = {
  login({ commit, dispatch }){
    var code_verifier = generateRandomString(128);
    commit('SET_CODE_VERIFIER', code_verifier)
    generateCodeChallenge(code_verifier).then(function(code_challenge) {
      const href = `https://login.tidal.com/authorize?lang=en&response_type=code&redirect_uri=${state.redirect_uri}&client_id=${state.client_id}&restrictSignup=true&code_challenge=${code_challenge}&code_challenge_method=S256`
      window.location.href = href
      /*const width = 600
      const height = 700
      const top = window.innerHeight/2 - height/2
      const left = window.innerWidth/2 - width/2
      const params = `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${width},height=${height},top=${top},left=${left}`
      window.open(href, 'targetWindow', params)
      window.addEventListener('message', event => {
        console.log(event)
        if (event.origin !== process.env.VUE_APP_PLAYER_URL) {
          return
        }
        const { data } = event;

        //if (data.source === 'lma-login-redirect') {
          //const { code } = data;
          dispatch('getAccessToken', data)
        //}
      }, false)*/
    })
  },
  getAccessToken({ commit, state, rootState, dispatch }, code){
    const params = new URLSearchParams()
    params.append('code', code)
    params.append('client_id', state.client_id)
    params.append('grant_type', 'authorization_code')
    params.append('redirect_uri', state.redirect_uri)
    params.append('restrictSignup', true)
    params.append('code_challenge_method', 'S256')
    params.append('code_verifier', state.code_verifier)

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    axios.post(`https://auth.tidal.com/v1/oauth2/token`, params, config)
    .then(response => {
      let access_token = response.data.access_token
      let refresh_token = response.data.refresh_token
      let expires_in = response.data.expires_in
      commit('SET_ACCESS_TOKEN', access_token)
      commit('SET_REFRESH_TOKEN', refresh_token)
      commit('SET_EXPIRES_IN', expires_in)

      dispatch('getSession').then(() => {
        //dispatch('services/setTidal', null, { root: true })

        let album = rootState.album.album
        if(album && album.tidal_id){
          dispatch('getAlbumTracks', { album_id: album.tidal_id })
        }
      })


    })
    .catch(error => {
      console.log(error)
    })
  },
  refreshToken({ commit }){
    console.log('refresh token')
    const params = new URLSearchParams()
    params.append('refresh_token', state.refresh_token)
    params.append('client_id', state.client_id)
    params.append('grant_type', 'refresh_token')

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    axios.post(`https://auth.tidal.com/v1/oauth2/token`, params, config)
    .then(response => {
      let access_token = response.data.access_token
      let expires_in = response.data.expires_in
      commit('SET_ACCESS_TOKEN', access_token)
      commit('SET_EXPIRES_IN', expires_in)
      //dispatch('getSession')
      //dispatch('search')
      //console.log(response)
    })
    .catch(error => {
      console.log(error)
    })
  },
  search({ state }) {
    const params = new URLSearchParams()
    params.append('query', 'Kendrick Lamar DAMN')
    params.append('countryCode', state.session.countryCode)
    params.append('types', 'ALBUMS')
    params.append('code_challenge_method', 'S256')
    params.append('code_verifier', state.code_verifier)
    params.append('token', state.access_token)

    axios.get(`https://api.tidal.com/v1/search/top-hits/`, {
      params: params,
      headers: {
        'Authorization': `Bearer ${state.access_token}`
      }
    })
    .then(response => {
      console.log(response)
    })
    .catch(error => {
      console.log(error)
    })
  },
  getSession({ commit, state }){

    return axios.get(`https://api.tidal.com/v1/sessions`, {
      headers: {
        'Authorization': `Bearer ${state.access_token}`
      }
    })
    .then(response => {
      commit('SET_SESSION', response.data)
    })
    .catch(error => {
      console.log(error)
    })
  },
  logout({ commit, dispatch }){
    const params = new URLSearchParams()
    params.append('sessionId', state.session.sessionId)

    axios.post(`https://api.tidal.com/v1/logout`, {
      params: params
    }, {
      headers: {
        'Authorization': `Bearer ${state.access_token}`,
        'X-Tidal-SessionId': `${state.session.sessionId}`
      }
    })
    .then(response => {

    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      commit('SET_IS_PLAYING', false)
      commit('CLEAR_ALL_TOKENS')
      //dispatch('services/setNoService', null, { root: true })
      //dispatch('services/setLoading', false, { root: true })
    })
    //location.reload()
  },
  getAlbumTracks({ commit, dispatch }, payload){
    let offset = payload.offset || 0

    if(offset == 0){
      commit('CLEAR_TRACKS')
    }
    axios.get(`https://api.tidal.com/v1/albums/${payload.album_id}/items`, {
      params: {
        'offset': offset,
        'streamingsessionid': state.session.sessionId,
        'countryCode': state.session.countryCode || 'US'
      },
      headers: {
        'Authorization': `Bearer ${state.access_token}`
      }
    })
    .then(response => {
      commit('CONCAT_TRACKS', response.data.items)
      if(response.data.totalNumberOfItems > state.tracks.length){
        let nextOffset = response.data.offset + response.data.limit
        dispatch('getAlbumTracks', { offset: nextOffset, album_id: payload.album_id })
      }

    })
    .catch(error => {
      console.log(error)
      console.log('refresh!')
      dispatch('refreshToken')
    })
  },
  loadAlbum({ commit }, payload){

    commit('SET_NOW_PLAYING_TRACKS')

    let index = 0
    if(payload.offset){
      index = payload.offset
    }

    if(state.nowPlayingTracks.length > index){
      commit('SET_CURRENT_INDEX', index)
      let trackID = state.nowPlayingTracks[index].item.id
      axios.get(`https://listen.tidal.com/v1/tracks/${trackID}/playbackinfopostpaywall`, {
        params: {
          'audioquality': 'LOSSLESS',
          'playbackmode': 'STREAM',
          'assetpresentation': 'FULL',
          'streamingsessionid': state.session.sessionId,
          'countryCode': state.session.countryCode
        },
        headers: {
          'Authorization': `Bearer ${state.access_token}`
        }
      })
      .then(response => {
        let manifest = JSON.parse(atob(response.data.manifest))
        commit('SET_TRACK', manifest.urls[0])
        commit('SET_IS_PLAYING', true)
      })
      .catch(error => {
        console.log(error)
        if(error.response.data.userMessage === "Requested quality is not allowed in user's subscription"){
          alert("A HiFi subscription is required for playback")
        }
      })
    }
  },
  getNextTrack({commit}){
    commit('SET_BUSY', true)

    let nextIndex = state.currentIndex + 1
    if(state.nowPlayingTracks.length > nextIndex){
      let trackID = state.nowPlayingTracks[nextIndex].item.id
      axios.get(`https://listen.tidal.com/v1/tracks/${trackID}/playbackinfopostpaywall`, {
        params: {
          'audioquality': 'LOSSLESS',
          'playbackmode': 'STREAM',
          'assetpresentation': 'FULL',
          'streamingsessionid': state.session.sessionId,
          'countryCode': state.session.countryCode
        },
        headers: {
          'Authorization': `Bearer ${state.access_token}`
        }
      })
      .then(response => {
        let manifest = JSON.parse(atob(response.data.manifest))
        commit('SET_NEXT_TRACK', manifest.urls[0])
        commit('SET_BUSY', false)
      })
      .catch(error => {
        console.log(error)
      })
    }

  },
  playNextTrack({ commit}){
    if(state.nextTrack){
      commit('INCREMENT_CURRENT_INDEX')
      commit('SET_TRACK', state.nextTrack)
    }
  },
  setIsPlaying({ commit}, playing){
    commit('SET_IS_PLAYING', playing)
  }
};

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default module;
