<template>
  <div>
    <h1 v-if="error"
        class="mx-4 mt-4 mb-4 text-2xl xs:text-3xl text-red-400">{{ error }}</h1>
    <div class="mx-4 mt-16">
      <AlbumGrid :albums="collection" />
    </div>
    <div
        v-if="loading"
        class="fixed left-0 top-0 w-full h-full wrapper flex items-center justify-center">
        <div class="w-1/4 md:w-1/12">
          <Loader />
        </div>
      </div>
  </div>
</template>


<script>
import axios from 'axios'
import AlbumGrid from '@/components/AlbumGrid'
export default {
  name: 'PageNotFound',
  components: {
    AlbumGrid
  },
  data(){
    return {
      loading: false,
      collection: [],
      error: null
    }
  },
  methods: {
    loadCollection(){
      this.loading = true
      axios.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/collection/404?thumbnail_size=200`)
        .then((response) => {
          this.collection = response.data.collection
          this.error = null
          document.title = `Page not found - Sleevenote`
        })
        .catch((error) => {
          console.log(error)
          this.error = "404"
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  mounted(){
    const ids = [
      'bd9c47cf-5e67-4363-8c61-d9c7a33e8ac5',
      'bd9c47cf-5e67-4363-8c61-d9c7a33e8ac5',
      'bd9c47cf-5e67-4363-8c61-d9c7a33e8ac5'
    ]
    this.loadCollection()

  }
};
</script>
