<template>
  <div class="m-6">

    <button class="mr-8 rounded border border-white px-4 py-2" @click="spotifyBtnClicked">
      <span v-if="spotifyConnected">Disconnect SPOTIFY</span>
      <span v-else>Connect SPOTIFY</span>
    </button>

    <button class="mr-8 rounded border border-white px-4 py-2" @click="refreshToken">Refresh Token</button>
    <button class="mr-8 rounded border border-white px-4 py-2" @click="play">
      <span v-if="playing">Pause</span>
      <span v-else>Play</span>
    </button>
    <button class="mr-8 rounded border border-white px-4 py-2" @click="getAlbum">Get Album</button>
    <div class="mt-6" v-if="$store.getters['spotify/connected']">
      <p>SPOTIFY <span>Connected</span></p>
    </div>

    <button class="mr-8 rounded border border-white px-4 py-2" @click="play2(album)">Play2</button>

    <div class="mt-6 hidden">
      <div v-for="album in albums" :key="album.apple_music_id" class="my-4">
        {{ album.title }} by {{ album.artist }}
        <button class="mr-8 rounded border border-white px-4 py-2" @click="play(album)">Play</button>
      </div>
    </div>




    <div class="mt-6" v-if="nowPlaying.title">
      Now playing: {{ nowPlaying.title }} by {{ nowPlaying.artist }}
    </div>

    <hr class="my-4" />
    <pre>{{ $store.state.spotify }}</pre>

  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
export default {
  name: 'Spotify',
  data(){
    return {
      albums: [
        {
          apple_music_id: '1542588729',
          spotify_id: 'spotify:album:0JZkzjywipWhUbdYPCEaMo',
          tidal_id: '',
          artist: 'The Black Keys',
          title: 'Brothers'
        },
        {
          apple_music_id: '286773545',
          spotify_id: 'spotify:album:0nLCUby65vPnn29qRPFq4I',
          tidal_id: '',
          artist: 'Nightmares On Wax',
          title: 'Thought So...'
        }
      ],
      playing: false
    }
  },
  computed: {
    spotifyConnected(){
      return this.$store.getters['spotify/connected']
    },
    nowPlaying(){
      let nowPlaying = {
        artist: '',
        title: ''
      }
      if(this.spotifyConnected){
        if(this.$store.state.spotify.playback){
          nowPlaying.artist = this.$store.state.spotify.playback.artists[0].name || '',
          nowPlaying.title = this.$store.state.spotify.playback.name || ''
        }
      }
      return nowPlaying
    }
  },
  mounted(){

    //this.$store.dispatch('tidal/getAlbumTracks', { album_id: tidalId })
  },
  methods: {
    refreshToken(){
      this.$store.dispatch('spotify/refreshToken')
    },
    spotifyBtnClicked(){
      if(this.spotifyConnected){
        this.$store.dispatch('spotify/logout')
      }else{
        this.$store.dispatch('spotify/login')
      }
    },
    play(){
      if(!this.albums[0].spotify_id){
        alert('Album unavailable with Spotify')
        return
      }

      let payload = {
        context_uri: this.albums[0].spotify_id,
        offset: { 'position': 0 }
      }
      this.$store.dispatch('spotify/play', payload)
      this.$store.dispatch('album/setNowPlayingAlbum', this.albums[0])
      return
    },
    getAlbum(){
      this.$store.dispatch('tidal/getAlbum')
    },
    play2(){
      this.$store.state.spotify.player.resume()
    }
  }
}
</script>