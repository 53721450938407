<template>
  <div>
    <div>
      <AlbumSearch />
      <div v-if="loading" class="ml-4 flex items-center">
        <div class="w-24">
          <Loader />
        </div>
      </div>
      <h1
        v-else-if="error"
        class="mx-4 mt-4 mb-4 text-2xl xs:text-3xl text-red-400">{{ error }}</h1>
      <div
        v-else
        class="mb-10">
        <div class="m-4 flex justify-between items-center">
          <div class="min-w-0 w-full">
            <button
              class="text-left text-xl xs:text-2xl text-black dark:text-white hover:underline dark:hover:underline cursor-pointer"
              @click="$emit('your-collection-clicked')">@{{ truncatedWallet }}'s collection</button><router-link v-if="loggedIn && !user.username" :to="{ name : 'profile' }" class="ml-1 hover:opacity-50 text-2xl transform rotate-45">✎</router-link>
          </div>
        </div>
        <div
          v-if="!loading && collection.length === 0"
          class="mx-4 text-2xl xs:text-3xl text-pink-400">Nothing here yet!</div>
        <AlbumGrid
          :albums="collection"
          :dragEnabled="enableDrag"
          :allowSelect="false"
          @drag-complete="dragComplete" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'
import AlbumSearch from '@/components/AlbumSearch'
import AlbumGrid from '@/components/AlbumGrid'
import { GetCollectionByUserId, GetTracksByNFTIDs } from '@/api/nfts/catalog'
import { GetUserProfile, GetUserNfts } from '@/api/nfts/sound'
import { GetCollection } from '@/api/nfts/mintsongs'

export const ENS_NETS = ['0x1', '0x3', '0x4']

export default {
  name: 'Wallet',
  components: {
    AlbumSearch,
    AlbumGrid,
  },
  data(){
    return {
      collection: [],
      ownedTokens: [],
      error: null,
      search: '',
      loading: false,
      enableDrag: false,
    }
  },
  watch: {
    "$route.params.username": function() {
      this.loadData()
    },
  },
  computed: {
    ...mapState('auth', {
      user: 'user'
    }),
    ...mapGetters('auth', {
      loggedIn: 'loggedIn',
    }),
    allowDrag(){
      return this.loggedIn && this.$route.params.username === this.user.username && this.collection.length > 0
    },
    truncatedWallet(){
      if(this.$route.params.wallet.length > 30){
        return this.$route.params.wallet.substring(0, 6) + '...'
      }else{
        return this.$route.params.wallet
      }

    }
  },
  mounted(){
    this.loadData()
  },
  methods: {
    async loadData(){
      this.loading = true

      let wallet = this.$route.params.wallet

      if(!wallet){
        this.loading = false
        return
      }

      this.ownedTokens = await this.loadTokensFromWallet(wallet)

      this.collection = this.ownedTokens

      this.collection.forEach(release => {
        release.owned_by_user = true
      })
      this.loading = false

      //let tokens = await this.loadTokensFromWallet()
      //this.saveTokensToCollection(tokens)

      //if not logged in
      //check ownership
    },
    async loadTokensFromWallet(address){
      let catalogNFTs = await this.loadCatalogNFTs(address)

      let soundNFTs = await this.loadSoundNFTs(address)

     //let mintNFTs = await this.loadMintNFTs(address)

      return [...catalogNFTs, ...soundNFTs]

      /*if(!result.data.data.account){
        this.ownedTokens = tokens
        return
      }

      let nfts = result.data.data.account.ownedTokens
      nfts.forEach(nft => {
        tokens.push(nft.id)
      })
      console.log(tokens)
      this.ownedTokens = tokens*/
    },
    async loadCatalogNFTs(address){
      try{
        let nfts = []
        let catalogIds = await GetCollectionByUserId(address)
        catalogIds = catalogIds.data.data.Token.map(r => r.tokenId)
        let catalogNFTs = await GetTracksByNFTIDs(catalogIds)

        if(!catalogNFTs.data.data) return nfts

        catalogNFTs.data.data.tracks.forEach(catalogNFT => {
          let nft = {
            id: catalogNFT.id,
            artist: catalogNFT.artist.name,
            title: catalogNFT.title,
            audio: `https://ipfs.io/ipfs/${catalogNFT.ipfs_hash_lossy_audio}`,
            front_cover: `https://ipfs.io/ipfs/${catalogNFT.ipfs_hash_lossy_artwork}`,
            is_nft: true,
            owner: address,
            nft_metadata: {
              id: catalogNFT.id,
              platform_id: catalogNFT.nft_id,
              platform: 'catalog',
              url: `https://beta.catalog.works/${catalogNFT.artist.handle}/${catalogNFT.short_url}`,
              contract_address: catalogNFT.contract_address,
              //purchased_at: catalogIds.data.data.Token.find(t => t.tokenId === catalogNFT.nft_id).transferEvents.find(te => te.to === address).map(te => te.blockTimestamp)
            }
          }
          nfts.push(nft)
        })
        return nfts
      }catch{
        return []
      }
    },
    async loadSoundNFTs(address){
      let user = await GetUserProfile(address)

      console.log(user.data.data.userByAddress.id)
      let edges = []
      let hasNextPage = true
      let after = null
      while(hasNextPage){
        let resp = await GetUserNfts(user.data.data.userByAddress.id, after)
        edges.push(...resp.data.data.user.nftsPaginated.edges)
        after = resp.data.data.user.nftsPaginated.pageInfo.endCursor
        hasNextPage = resp.data.data.user.nftsPaginated.pageInfo.hasNextPage
      }

      console.log(edges)

      let nfts = []

      for (const edge of edges) {

        const token = edge.node

        let nft = {
            id: token.id,
            artist: token.release.artist.name,
            title: `${token.release.title} #${token.serialNumber}`,
            //audio: audio_url.data?.data?.audioFromTrack?.audio?.url,
            front_cover: token.release.coverImage.url,
            is_nft: true,
            owner: address,
            nft_metadata: {
              id: token.id,
              platform_id: token.id,
              track_id: token.release.track.id,
              platform: 'sound',
              serialNumber: token.serialNumber,
              soundHandle: token.release.artist.soundHandle,
              releaseSlug: token.release.titleSlug,
              url: `https://www.sound.xyz/${token.release.artist.soundHandle}/${token.release.titleSlug}`,
              mintInfoId: token.id,
              owner_wallet: address
            }
          }
          nfts.push(nft)
      }

      return nfts//.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id && t.id===v.id))===i)
    },
    async loadMintNFTs(address){
      let tokens = await GetCollection(address)
      console.log(tokens)
      let nfts = []

      if(tokens.data.length === 0){
        return nfts
      }

      for (const token of tokens) {

        let nft = {
          id: token.id,
          artist: token.attributes.metadata.artist,
          title: token.attributes.metadata.title,
          audio: token.attributes.metadata.losslessAudio.replace('ipfs://', 'https://ipfs.io/ipfs/'),
          front_cover: token.attributes.metadata.animation_url.replace('ipfs://', 'https://ipfs.io/ipfs/'),
          is_nft: true,
          owner: address,
          nft_metadata: {
            id: token.id,
            platform_id: token.id,
            track_id: token.release.track.id,
            platform: 'mintsongs',
            url: token.attributes.metadata.external_url,
          }
        }
        nfts.push(nft)
      }

      return nfts//.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id && t.id===v.id))===i)
    },
    dragComplete(albums){
      this.loading = true
      axios.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/collection/reorder`, {
        collection: albums
      }, {
        headers: {
          Authorization: "Bearer " + this.$store.state.auth.token
        }
      })
        .then((response) => {
          //console.log(response)
         // this.collection = response.data.collection
          this.error = null
        })
        .catch((error) => {
          console.log(error)
          this.error = "User not found"
        })
        .finally(() => {
          this.loading = false
        })
    },
  }
}
</script>