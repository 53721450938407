import axios from 'axios'
import { generateRandomString, generateCodeChallenge } from '@/utils/random'

export function apiHeaders () {
  return new Headers({
    Authorization: 'Bearer ' + getters['accessToken'],
    Accept: 'application/json', 'Content-Type': 'application/json'
  });
}

const constants = {
  code_verifier_name: 'spotify-code-verifier',
  access_token_name: 'spotify-access-token',
  refresh_token_name: 'spotify-refresh-token',
  device_id_name: 'spotify-device-id',
  player_name: process.env.VUE_APP_APP_NAME,
  redirect_uri: process.env.VUE_APP_SPOTIFY_REDIRECT_URI,
  client_id: process.env.VUE_APP_SPOTIFY_CLIENT_ID
};

const state = {
  ready: false,
  redirect_uri: constants.redirect_uri,
  client_id: constants.client_id,
  code_verifier: localStorage.getItem(constants.code_verifier_name) || '',
  access_token: localStorage.getItem(constants.access_token_name) || '',
  refresh_token: localStorage.getItem(constants.refresh_token_name) || '',
  expires_at: null,
  device_id: localStorage.getItem(constants.device_id_name) || '',
  autoplay: false,
  playdata: '',
  playback: null,
  playbackContext: null,
  player: null
};

const getters = {
  connected(state) {
    return state.access_token ? true : false
  },
  accessToken(state) {
    return state.access_token || ''
  },
  refreshToken(state) {
    return state.refresh_token
  },
  deviceID(state){
    return state.device_id || ''
  },
  getPlayback(state){
    return state.playback
  },
  getPlaybackContext(state){
    return state.playbackContext
  },
  getPlayData(state){
    return state.playdata
  },
  getPlayer(state){
    return state.player
  }
};

const mutations = {
  SET_ACCESS_TOKEN(state, access_token) {
    state.access_token = access_token
    localStorage.setItem(constants.access_token_name, access_token)
  },
  SET_REFRESH_TOKEN(state, refresh_token) {
    state.refresh_token = refresh_token
    localStorage.setItem(constants.refresh_token_name, refresh_token)
  },
  CLEAR_ALL_TOKENS(state) {
    state.access_token = ''
    state.refresh_token = ''
    state.code_verifier = ''
    state.device_id = ''
    localStorage.removeItem(constants.code_verifier_name)
    localStorage.removeItem(constants.access_token_name)
    localStorage.removeItem(constants.refresh_token_name)
    localStorage.removeItem(constants.device_id_name)
  },
  SET_DEVICE_ID(state, device_id) {
    state.device_id = device_id
    localStorage.setItem(constants.device_id_name, device_id)
  },
  SET_CODE_VERIFIER(state, code_verifier) {
    state.code_verifier = code_verifier
    localStorage.setItem(constants.code_verifier_name, code_verifier)
  },
  SET_EXPIRES_AT(state, expires_at) {
    state.expires_at = expires_at
  },
  SET_AUTOPLAY(state, autoplay) {
    state.autoplay = autoplay
    if(state.player)state.player.resume()
  },
  SET_PLAY_DATA(state, data){
    state.playdata = data
  },
  SET_NOW_PLAYING(state, data){
    state.playback = data
  },
  SET_PLAYBACK_CONTEXT(state, data){
    state.playbackContext = data
  },
  SET_PLAYER(state, player){
    state.player = player;
  },
  SET_READY(state, ready){
    state.ready = ready
  }
};

const actions = {
  login({ state, commit, dispatch }){
    var code_verifier = generateRandomString(128);
    commit('SET_CODE_VERIFIER', code_verifier)
    generateCodeChallenge(code_verifier).then(function(code_challenge) {
      const scopes = encodeURI('streaming+user-read-email+user-modify-playback-state+user-read-private')
      const href = `https://accounts.spotify.com/authorize?response_type=code&client_id=${state.client_id}&redirect_uri=${state.redirect_uri}&code_challenge=${code_challenge}&code_challenge_method=S256&scope=${scopes}`
      window.location.href = href
    })
  },
  getAccessToken({ state, commit, dispatch }, code){
    const params = new URLSearchParams()
    params.append('code', code)
    params.append('client_id', state.client_id)
    params.append('grant_type', 'authorization_code')
    params.append('redirect_uri', state.redirect_uri)
    params.append('restrictSignup', true)
    params.append('code_verifier', state.code_verifier)

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    axios.post(`https://accounts.spotify.com/api/token`, params, config)
    .then(response => {
      let access_token = response.data.access_token
      let refresh_token = response.data.refresh_token
      let expires_in = response.data.expires_in

      commit('SET_ACCESS_TOKEN', access_token)
      commit('SET_REFRESH_TOKEN', refresh_token)

      let now = new Date()
      now.setSeconds(now.getSeconds() + expires_in);
      commit('SET_EXPIRES_AT', now)

      let timeout = (expires_in * 1000) - 10000
      setTimeout(() => {
        dispatch('refreshToken')
      }, timeout)

      //dispatch('services/setSpotify', null, { root: true })
      dispatch('initPlayer')
    })
    .catch(error => {
      console.log(error)
    })

  },
  refreshToken({ state, commit, dispatch }){
    //alert('refreshToken')
    console.log('refreshToken')
    const params = new URLSearchParams()
    params.append('refresh_token', state.refresh_token)
    params.append('client_id', state.client_id)
    params.append('grant_type', 'refresh_token')

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + (new Buffer(state.client_id + ':' + process.env.VUE_APP_SPOTIFY_CLIENT_SECRET).toString('base64'))
      }
    }

    axios.post(`https://accounts.spotify.com/api/token`, params, config)
    .then(response => {
      let access_token = response.data.access_token
      let refresh_token = response.data.refresh_token
      let expires_in = response.data.expires_in
      commit('SET_ACCESS_TOKEN', access_token)
      commit('SET_REFRESH_TOKEN', refresh_token)

      let now = new Date()
      now.setSeconds(now.getSeconds() + expires_in);
      commit('SET_EXPIRES_AT', now)

      let timeout = (expires_in * 1000) - 10000
      setTimeout(() => {
        dispatch('refreshToken')
      }, timeout)
    })
    .catch(error => {
      console.log(error)
    })
  },
  logout({ commit, dispatch }){
    commit('CLEAR_ALL_TOKENS')
    //dispatch('services/setNoService', null, { root: true })
    location.reload()
  },
  initPlayer({ commit, state, dispatch }){
  //alert('initPlayer')
    async function waitForSpotifyWebPlaybackSDKToLoad() {
      return new Promise((resolve) => {
        if (window.Spotify) {
          resolve(window.Spotify);
        } else {
          window.onSpotifyWebPlaybackSDKReady = () => {
            resolve(window.Spotify);
          };
        }
      });
    }

    async function waitUntilUserHasSelectedPlayer(sdk) {
      return new Promise((resolve) => {
        let interval = setInterval(async () => {
          let state = await sdk.getCurrentState();
          if (state !== null) {
            resolve(state);
            clearInterval(interval);
          }
        });
      });
    }

    (async () => {
      const { Player } = await waitForSpotifyWebPlaybackSDKToLoad();

      // eslint-disable-next-line
      const player = new Player({
        name: constants.player_name,
        getOAuthToken: (cb) => {
          cb(state.access_token);
        }
      });

      // Error handling
      player.addListener("initialization_error", ({ message }) => {
        console.error(message);
      });

      player.addListener("authentication_error", ({ message }) => {
        console.error(message);
        dispatch('refreshToken')
      });

      player.addListener("account_error", ({ message }) => {
        console.error(message);
      });

      player.addListener("playback_error", ({ message }) => {
        console.error(message);

        //dispatch('login')
      });

      player.addListener("player_state_changed", (state) => {
        if (state) {
          let { current_track } = state.track_window;
          commit('SET_NOW_PLAYING', current_track)
          commit("SET_PLAYBACK_CONTEXT", state)

          if(!state.loading && !state.paused){
            commit('album/IS_PLAYING', true, { root: true})
          }else{
            commit('album/IS_PLAYING', false, { root: true})
          }
          //dispatch("setPlayback");
        }
      });

      // Ready
      player.addListener("ready", ({ device_id }) => {
        commit("SET_DEVICE_ID", device_id);
        commit("SET_READY", true)
        //if(state.autoplay){
        //  dispatch('play', state.playdata)
        //}

        //dispatch('transferUsersPlayback')
      });

      // Not Ready
      player.addListener("not_ready", ({ device_id }) => {
        console.log("Device ID has gone offline", device_id);
        commit("SET_DEVICE_ID", null);
        commit("SET_READY", false)
      });

      // Connect to the player!
      let connected = await player.connect();
      commit('SET_PLAYER', player);

      if (connected) {
        await waitUntilUserHasSelectedPlayer(player);
      }
    })();

  },
  setAutoplay({ commit }, autoplay){
    commit("SET_AUTOPLAY", autoplay)
  },
  play({ commit, getters, dispatch }, { context_uri, offset, uris }) {
    const accessToken = getters['accessToken']
    const url = `https://api.spotify.com/v1/me/player/play?device_id=${state.device_id}`

    const data = {
      offset,
      uris,
      ...(context_uri && { context_uri })
    }

    const config = {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    }

    commit('SET_PLAY_DATA', data)

    axios.put(url, data, config).then(response => {

      commit('SET_AUTOPLAY', false)

    }).catch(error => {

      console.log('error', error.response)
      commit('SET_AUTOPLAY', true)

      //alert(error.response.status)

      if(error.response.status === 401){
        //alert('refresh', getters['refreshToken'])
        //dispatch('login')
        return
      }

      if(error.response.status === 404){
        dispatch('initPlayer')
      }

      if (error.response.status === 403 && error.response.data.error.reason === "PREMIUM_REQUIRED") {
        alert("A premium account is required for playback")
      }

    })

  },
  resume({ getters, dispatch }){
    const accessToken = getters['accessToken']
    const url = `https://api.spotify.com/v1/me/player/play?device_id=${state.device_id}`

    const data = {}

    const config = {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    }

    axios.put(url, data, config).then(response => {
    }).catch(error => {
      if(error.response.status === 403 || error.response.status === 401) {
        dispatch('refreshToken')
      }
    })
    //getters['getPlayer'].resume()
  },
  pause({ getters, dispatch }){
    console.log('pause')
    const accessToken = getters['accessToken']
    const url = `https://api.spotify.com/v1/me/player/pause?device_id=${state.device_id}`

    const data = {}

    const config = {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    }

    axios.put(url, data, config).then(response => {
    }).catch(error => {
      console.log(error)
      if(error.response.status === 403 || error.response.status === 401) {
        dispatch('refreshToken')
      }
    })
    //getters['getPlayer'].pause()
  },
  transferUsersPlayback({ getters, commit, state, dispatch }) {
    console.log('transferUsersPlayback')
    const accessToken = getters['accessToken']
    const url = `https://api.spotify.com/v1/me/player`

    const data = {
      device_ids: [state.device_id],
      play: true
    }

    const config = {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    }

    axios.put(url, data, config).then(response => {
      console.log(response)

    }).catch(error => {
      console.log(error)
    })
  }
};

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default module;
