<template>
 <div>
   <div class="mx-4 mt-16">
    <h1 class="text-2xl xs:text-3xl mb-10">Create your collection</h1>
    <form @submit.prevent="submit" class="text-base xs:text-xl">

      <div class="mb-10 flex flex-wrap">
        <div class="w-full flex justify-between max-w-md">
          <label class="mr-2 w-20 xs:w-24">Email</label>
          <input
            v-model="email"
            type="text"
            required
            class="flex-grow bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
        </div>
        <div
          v-if="errors.email"
          class="text-sm xs:text-lg text-red-500 w-full max-w-md">
          {{ errors.email.join(',') }}
        </div>
      </div>

      <div class="mb-10 flex flex-wrap">
        <div class="w-full flex justify-between max-w-md">
          <label class="mr-2 w-20 xs:w-24">Password</label>
          <input
            v-model="password"
            type="password"
            autocomplete="new-password"
            id="new-password"
            required
            class="flex-grow bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
        </div>
        <div
          v-if="errors.password"
          class="text-sm xs:text-lg text-red-500 w-full max-w-md">
          {{ errors.password.join(',') }}
        </div>
      </div>

      <div class="mb-10 flex flex-wrap">
        <div class="w-full flex justify-between max-w-md">
          <label class="mr-2 w-20 xs:w-24">Username</label>
          @
          <input
            v-model="username"
            type="text"
            required
            autocapitalize="off"
            class="flex-grow bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
        </div>
        <div
          v-if="errors.username"
          class="text-sm xs:text-lg text-red-500 w-full max-w-md">
          {{ errors.username.join(',') }}
        </div>
        <div v-else-if="username && !validating"
          class="text-sm xs:text-lg text-green-500 w-full max-w-md">
          Available!
        </div>
      </div>

      <div>
        <button
          type="submit"
          :disabled="loading"
          class="button w-64 h-12 mb-4"
          :class="{'cursor-not-allowed': loading, 'hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black': !loading}">
          <span v-if="!loading">GO</span>
          <span v-else>
            <Loader></Loader>
          </span>
        </button>

        <div class="text-base xs:text-lg mb-2">Have an account? <router-link
          :to="{ name: 'login' }"
          class="shrink-0 border-b-2 border-transparent hover:border-black dark:hover:border-white">Log in</router-link>
        </div>
        <div class="text-base xs:text-lg">Have a wallet? <button
          @click.prevent="$emit('connect-wallet')"
          class="flex-shrink-0 wallet-connect-button text-xl">Connect</button></div>
      </div>

    </form>
    </div>
 </div>
</template>

<script>
import axios from 'axios'
import debounce from 'lodash/debounce'

export default {
  name: 'Signup',
  components: {
  },
  data(){
    return{
      email: '',
      password: '',
      username: '',
      errors: {},
      loading: false,
      validating: false
    }
  },
  watch: {
    email(value) {
      this.validating = true
      this.validate(`email=${value}`)
    },
    password(value) {
      this.validating = true
      this.validate(`password=${value}`)
    },
    username(value) {
      this.validating = true
      this.validate(`username=${value}`)
    },
  },
  methods: {
    validate(param) {
      this.validating = true
      console.log('validate', param)
      axios.get(`${process.env.VUE_APP_API_URL}/listener/validate?${param}`)
      .then((response) => {
        this.errors = []
      })
      .catch((error) => {
        this.errors = error.response.data.message
      })
      .finally(() => {
        this.validating = false
      })
    },
    submit(){
      this.loading = true;
      axios.post(`${process.env.VUE_APP_API_URL}/listener/register`, {
        email: this.email,
        username: this.username,
        password: this.password,
      })
      .then((response) => {
        const token = response.data.token
        const user = response.data.user
        this.$store.commit('auth/SET_TOKEN', token)
        this.$store.commit('auth/SET_USER', user)
        //this.$store.dispatch('collection/getUserCollection');
        let route = this.$route.query.redirect || { name: 'home' }
        this.$router.push(route)
      })
      .catch((error) => {
        this.errors = error.response.data.message
      })
      .finally(() => {
        this.loading = false
      })
    }
  },
  mounted(){
  },
  created() {
    this.validate = debounce(this.validate, 500);
  },
};
</script>
