<template>
  <div
    class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center"
    @click.stop="$emit('close')">

    <div class="modal-container">

      <div class="flex flex-col px-8">
        <div class="flex flex-col mb-8">
          <button
            @click="$emit('connect-wallet')"
            class="hidden mb-4 flex-shrink-0 wallet-connect-button text-xl">Connect wallet</button>

          <router-link
            :to="loginLink"
            class="modal-link">Log in to your collection</router-link>
          <router-link
            :to="signupLink"
            class="modal-link">(or sign up)</router-link>
        </div>

        <button
            @click.stop="$emit('close')"
            class="modal-close">Close</button>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'SignUpLoginModal',
  computed: {
    signupLink(){
      let link = {
        name: 'signup',
      }

      if(this.$route.name === 'release' || this.$route.name === 'album'){
        link.query = { redirect: this.$route.fullPath }
      }

      return link
    },
    loginLink(){
      let link = {
        name: 'login',
      }

      if(this.$route.name === 'release' || this.$route.name === 'album'){
        link.query = { redirect: this.$route.fullPath }
      }

      return link
    },
  }
}
</script>