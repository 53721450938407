<template>
  <header class="text-sm fixed top-0 h-10 z-50 bg-light dark:bg-dark text-black dark:text-white bg-opacity-80 transition-opacity w-full px-4 py-2 flex justify-between items-center">

      <div class="flex flex-1">
        <Logo></Logo>
        <div v-if="showCollectionLeft" class="flex">
          <router-link
            :to="{ name: 'usercollection', params: { username: backUsername } }"
            class="flex-shrink-0 ml-2 link">
            <span
              v-if="loggedIn && user && isUsersCollectionPage"
              class="hidden sm:flex">Go to your collection</span>
            <span
              v-else
              class="hidden sm:flex">Back to collection</span>
            <span class="flex sm:hidden">Collection</span>
          </router-link>
        </div>
        <router-link
          v-if="showGoToYourCollection"
          :to="{ name: 'usercollection', params: { username: this.user.username || this.user.wallet } }"
          class="flex-shrink-0 ml-2 link">Go to your collection</router-link>
        <button
          v-if="showYourCollection"
          @click="$emit('clear-search')"
          class="flex-shrink-0 ml-2 border-b-2 border-transparent">
          Your collection
        </button>
        <div v-if="showBeta" class="flex-shrink-0 flex items-center">
          <svg class="h-4" x="0px" y="0px" viewBox="0 0 427.7 355.3" xml:space="preserve">
            <g>
              <path fill="currentColor" d="M136.2,137.4c-5.3,0-9.7-0.7-13.4-2.1c-3.7-1.4-6.7-3.2-9.1-5.4c-2.4-2.2-4.3-4.5-5.6-6.9c-1.4-2.4-2.4-4.7-3.1-6.7
                l-2.1,19.3H88.1V0.6h19.6v55.3c0.8-1.6,2.1-3.4,3.8-5.3c1.7-1.9,3.8-3.6,6.3-5.3c2.5-1.6,5.4-3,8.8-4c3.3-1,7.1-1.5,11.3-1.5
                c11.7,0,21,4.1,27.8,12.4c6.9,8.2,10.3,20.2,10.3,36c0,9.9-1.5,18.6-4.5,25.9c-3,7.4-7.4,13.1-13.3,17.2
                C152.3,135.3,145,137.4,136.2,137.4z M132.4,122.7c7.1,0,13-2.8,17.5-8.4c4.5-5.6,6.8-14.5,6.8-26.9c0-10.7-2.1-18.8-6.3-24.4
                c-4.2-5.6-10.3-8.4-18.2-8.4c-5.7,0-10.3,1.3-13.9,3.8c-3.6,2.5-6.2,6.2-7.9,11.1c-1.7,4.9-2.5,10.9-2.6,18c0,12.5,1.9,21.5,5.8,27
                C117.4,120,123.7,122.7,132.4,122.7z"/>
              <path fill="currentColor" d="M207.1,93.1c0,5.6,0.9,10.7,2.8,15.1c1.8,4.5,4.6,8,8.4,10.5c3.8,2.6,8.4,3.9,14,3.9c5.6,0,10.4-1.3,14.5-3.8
                s6.8-6.3,8-11.4h18c-1.1,6.4-3.6,11.9-7.7,16.3c-4,4.5-8.9,7.9-14.6,10.1c-5.7,2.3-11.7,3.4-17.9,3.4c-8.9,0-16.7-1.9-23.5-5.8
                c-6.8-3.8-12.1-9.3-15.8-16.5c-3.8-7.1-5.7-15.7-5.7-25.5c0-9.7,1.7-18.4,5.2-25.8c3.5-7.5,8.4-13.3,15-17.6
                c6.5-4.2,14.4-6.4,23.6-6.4c9.1,0,16.7,1.9,22.9,5.8c6.2,3.9,10.9,9.3,14.1,16.3c3.2,7,4.8,15.2,4.8,24.7v6.4H207.1z M207.1,80.5
                h47.6c0-4.9-0.8-9.4-2.5-13.4c-1.7-4-4.3-7.2-7.8-9.5c-3.5-2.4-7.9-3.5-13.1-3.5c-5.4,0-9.9,1.3-13.4,3.9c-3.6,2.6-6.3,5.9-8,10
                C208,72,207.1,76.2,207.1,80.5z"/>
              <path fill="currentColor" d="M333.8,55.6h-21.1l0.1,56c0,3.1,0.3,5.3,1,6.8c0.6,1.4,1.7,2.3,3.3,2.7c1.5,0.4,3.6,0.6,6.3,0.6h11v12.6
                c-1.2,0.5-3,0.9-5.4,1.3c-2.4,0.4-5.5,0.5-9.2,0.5c-7,0-12.4-0.9-16.2-2.8c-3.8-1.9-6.5-4.6-8-8.1c-1.5-3.5-2.2-7.8-2.2-12.9V55.6
                H278v-14h15.8l4.8-27.6h14.1v27.5h21.1V55.6z"/>
              <path fill="currentColor" d="M375.5,137.4c-6.1,0-11.4-1.1-16.1-3.3c-4.7-2.2-8.4-5.3-11-9.4c-2.7-4.1-4-9.1-4-14.9c0-10.4,3.7-18.2,11-23.3
                c7.4-5.1,19.1-7.9,35.4-8.2l15.4-0.5V71c0-5.4-1.6-9.6-4.8-12.7c-3.2-3.1-8.1-4.6-14.8-4.5c-5,0-9.5,1.2-13.4,3.5
                c-3.9,2.3-6.5,6.1-7.7,11.4h-16.9c0.4-6.2,2.2-11.5,5.4-15.8c3.2-4.3,7.7-7.5,13.4-9.7c5.7-2.2,12.4-3.3,20.1-3.3
                c8.5,0,15.6,1.2,21.1,3.5c5.5,2.3,9.6,5.7,12.3,10.2c2.7,4.5,4,9.9,4,16.3v65.7h-16.4l-1.5-17.3c-3.5,7.2-7.9,12.2-13.3,14.9
                C388.2,136,382.2,137.4,375.5,137.4z M381.5,123.8c3,0,5.9-0.5,8.8-1.6c2.9-1.1,5.5-2.5,7.9-4.4c2.4-1.8,4.3-3.9,5.7-6.2
                c1.4-2.3,2.2-4.7,2.2-7.1V89.1l-12.7,0.2c-6.5,0.1-12,0.7-16.5,2c-4.5,1.3-8,3.2-10.4,5.9c-2.4,2.7-3.6,6.2-3.6,10.6
                c0,5,1.8,8.9,5.3,11.8C371.8,122.4,376.2,123.8,381.5,123.8z"/>
            </g>
          </svg>
        </div>
      </div>

      <div
        v-if="showConnectWallet || showSignupLogin"
        class="flex-1 flex justify-center space-x-2">
        <button
          v-if="showConnectWallet"
          @click="$emit('connect-wallet')"
          class="flex-shrink-0 wallet-connect-button">
          <span>Connect</span> <span class="hidden sm:inline">wallet</span></button>

        <router-link
              :to="{ name: 'signup' }"
              class="flex-shrink-0 z-10 rounded-md px-2 py-0.5 text-white dark:text-black transition-color bg-gray-300 hover:bg-gray-400">
              <span>Sign up</span> <span class="hidden sm:inline">with email</span></router-link>

        <router-link
              :to="{ name: 'login' }"
              class="flex-shrink-0 z-10 rounded-md px-2 py-0.5 text-white dark:text-black transition-color bg-gray-300 hover:bg-gray-400">
              Log in</router-link>
      </div>


      <div
        v-if="album"
        class="flex-1 flex justify-center">
        <AddToCollection
          :album="album"
          @signup-login-clicked="$emit('signup-login-clicked')"
          @remove-from-collection-clicked="$emit('remove-from-collection-clicked')"
          @remove-from-library="$emit('remove-from-library')" />
      </div>
      <div
        v-else-if="showLabelOrArtistLink"
        class="flex-1 flex justify-center space-x-2">

        <a
          href="https://create.sleevenote.com"
          class="flex-shrink-0 z-10 rounded-md px-2 py-0.5 text-white dark:text-black transition-color bg-mint hover:bg-opacity-80">
          <span>Label or artist?</span> <span class="hidden sm:inline">Create a release</span>
        </a>
      </div>

      <div v-if="album" class="flex-1 flex justify-end">
        <button
          v-if="album"
          @click="$emit('open-clicked')"
          class="cursor-pointer link">Info/Links</button>
      </div>

      <div v-if="showBurger" class="flex-1 flex justify-end">
        <button @click="$emit('burger-clicked')" class="w-4">
          <svg
            x="0px" y="0px" viewBox="0 0 16 12" style="enable-background:new 0 0 16 12;" xml:space="preserve">
            <g>
              <rect fill="currentColor"  width="16" height="2.14"/>
            </g>
            <g>
              <rect fill="currentColor"  y="4.93" width="16" height="2.14"/>
            </g>
            <g>
              <rect fill="currentColor"  y="9.86" width="16" height="2.14"/>
            </g>
          </svg>
        </button>
      </div>

  </header>
</template>

<script>
import Logo from '@/components/Logo'
import AddToCollection from '@/components/AddToCollection'
import { mapState, mapGetters } from 'vuex'

export default {
  name: "Header",
  components: {
    Logo,
    AddToCollection,
  },
  data(){
    return {
      dark: false
    }
  },
  watch: {
    dark(val){
      val ? document.documentElement.classList.add("dark") : document.documentElement.classList.remove("dark")
    }
  },
  computed: {
    ...mapState('album', ['album']),
    ...mapState('auth', ['user']),
    ...mapState('web3', ['connected']),
    ...mapGetters('auth', {
      loggedIn: 'loggedIn',
    }),
    backUsername(){
      if(this.$store.state.collection.prev_username){
        return this.$store.state.collection.prev_username
      }

      if(this.loggedIn){
        return this.user.username
      }

      return null
    },
    appleMusicConnected(){
      return this.$store.getters['musickit/connected']
    },
    tidalConnected(){
      return this.$store.getters['tidal/connected']
    },
    spotifyConnected(){
      return this.$store.getters['spotify/connected']
    },
    showAppleMusicLink(){
      if(this.album.apple_music_id){
        return true
      }else{
        return false
      }
    },
    showBandcampLink(){
      if(this.album.bandcamp_id){
        return true
      }

      return false
    },
    showNftLink(){
      if(this.album.artist === 'Teen Daze'){
        return true
      }
      return false
    },
    bandcampTitle(){
      if(this.album.artist === 'Teen Daze'){
        return 'Bandcamp'
      }
      return 'Buy'
    },
    showSignupLogin(){
      return false
      //return !this.loggedIn && (this.$route.name === 'home' || this.$route.name === 'usercollection' || this.$route.name === 'about')
    },
    showConnectWallet(){
      return false
      //return !this.loggedIn && !this.connected && (this.$route.name === 'home' || this.$route.name === 'usercollection' || this.$route.name === 'wallet' || this.$route.name === 'about')
    },
    showLabelOrArtistLink(){
      return !this.loggedIn && (this.$route.name === 'home' || this.$route.name === 'about')
    },
    showAbout(){
      return false
      //return !this.loggedIn && (this.$route.name === 'home' || this.$route.name === 'usercollection')
    },
    showYourCollection(){
      return this.loggedIn && this.$route.name === 'usercollection' && this.isUsersCollectionPage
    },
    showBurger(){
      return (this.$route.name === 'home' || this.$route.name === 'usercollection' || this.$route.name === 'profile' || this.$route.name === 'about')
      //return this.loggedIn &&
      //  (this.$route.name === 'home' || this.$route.name === 'usercollection' || this.$route.name === 'profile' || this.$route.name === 'about')
    },
    showCollectionLeft(){
      return this.backUsername && (this.$route.name === 'album' || this.$route.name === 'release')
    },
    showGoToYourCollection(){
      return this.loggedIn &&
        ((this.$route.name === 'home' || this.$route.name === 'profile') ||
        (this.$route.name === 'usercollection' && !this.isUsersCollectionPage))
    },
    isUsersCollectionPage(){
      return this.$route.params.username === this.user.username || this.$route.params.username === this.user.wallet
    },
    showBeta(){
      return false/* !this.showCollectionLeft &&
        !this.showGoToYourCollection &&
        !this.showYourCollection &&
        this.$route.name !== 'release' &&
        this.$route.name !== 'start' &&
        this.$route.name !== 'not-found' &&
        this.$route.name !== 'wallet'*/
    },

  },
};
</script>