<template>
  <div v-if="albums.length > 0" class="mt-6 mb-12">
    <button
        v-for="(album, index) in albums" :key="index"
        @click="goToAlbum(album)"
        class="flex justify-start items-center w-full p-1 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-500 cursor-pointer">
      <div class="flex-shrink-0 mr-4">
        <div class="cover-list m-2 relative">
          <img v-if="album.is_nft" class="absolute left-0 top-0 shadow-grid w-full h-full object-cover" src="@/assets/gif/nft_owner.gif" />
          <img :src="album.front_cover" width="75px" height="75px" class="w-18 h-18 z-10" :style="{ 'padding': album.is_nft ? '4%' : '' }" />
          <div v-if="album.back_cover" class="flex-shrink-0 absolute -left-1.5 top-1.5 w-full h-full object-cover">
            <img
              :src="album.back_cover"
              width="75px"
              height="75px"
              class="w-18 h-18"
            />
          </div>
        </div>
      </div>
      <div class="text-left min-w-0">
        <p><span class="font-bold">{{ album.title }}</span> <span v-if="album.year">({{ album.year }})</span></p>
        <p>by {{ album.artist }}</p>
      </div>
    </button>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'AlbumsList',
  components: {
  },
  props: {
    albums: Array,
  },
  data() {
    return {
      showOfficialInfo: false,
      showAlbumApproved: false,
      showPending: false,
    };
  },
  methods: {
    goToAlbum(album){
      if(album.id){
        this.$router.push({ name: 'release', params: { id: album.id }})
      }else{
        this.createAlbum(album.spotify_id)
      }
    },
    createAlbum(spotify_id){
      this.$root.$emit('creating-album');
      axios.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/album/create`, { spotify_id: spotify_id })
        .then((response) => {
          this.$router.push({ name: 'release', params: { id: response.data.album.id }})
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {

        })
    },
    infoClicked(album) {
      if (album.id) {
        if (album.official) {
          this.showOfficialInfo = true;
        } else if (album.approved) {
          this.showAlbumApproved = true;
        } else {
          this.showPending = true;
        }
      }
    },
    okClicked() {
      this.showOfficialInfo = false;
      this.showAlbumApproved = false;
      this.showPending = false;
    },
  },
};
</script>

<style scoped>
table, th, td {
  border: 0px solid orange;
}

.cover-list {
  display: flex;
  gap: 0.25rem;
}

</style>
