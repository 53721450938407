<template>
  <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center" @click.stop="$emit('close')">

    <div class="modal-container" @click.stop="$emit('dont-close')">

    <div class="flex flex-col px-8">

        <div class="text-xl m-auto mb-4 text-black dark:text-white">Are you sure?</div>

        <form autocomplete="off" @submit.prevent="deleteAccount">
          <div class="mb-4 flex flex-wrap relative">
            <div class="w-full flex justify-between max-w-md">
              <label class="mr-2">Password</label>
              <input
                v-model="password"
                type="password"
                required
                autocomplete="new-password"
                class="flex-grow bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
            </div>
          </div>

          <button
            :disabled="loading"
            type="submit"
            class="modal-link"
            :class="{'cursor-not-allowed': loading, 'hover:border-white': !loading}">
            <span v-if="!loading">Yes, delete</span>
            <div
              v-else
              class="w-6">
              <Loader></Loader>
            </div>
          </button>
        </form>

        <button
          @click.stop="$emit('close')"
          class="modal-close">
          Cancel
        </button>

        <div
          v-if="error"
          class="mt-2 text-sm xs:text-lg text-red-500 w-full max-w-md h-5">
          {{ error }}
        </div>
        <div v-else class="mt-2 text-sm xs:text-lg h-5"></div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
export default {
  name: 'DeleteAccountModal',
  data(){
    return{
      loading: false,
      password: null,
      error: null
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  watch: {
    password(val){
      if(val){
        this.error = null
      }
    }
  },
  methods: {
    deleteAccount(){
      if(this.loading)return;

      this.loading = true
      axios.post(`${process.env.VUE_APP_API_URL}/listener/destroy`, {
        id: this.user.id,
        password: this.password
      }, {
        headers: {
          Authorization: "Bearer " + this.$store.state.auth.token
        }
      })
      .then((response) => {
        console.log(response)

        window.localStorage.removeItem("token")
        window.localStorage.removeItem("user")
        this.$store.commit('auth/DELETE_TOKEN')
        this.$store.commit('auth/DELETE_USER')

        this.$router.replace({ name: 'home' })
      })
      .catch((error) => {
        console.log(error)
        this.error = error.response.data.error
        this.password = null
      })
      .finally(() => {
        this.loading = false
      })
    },
  }
}
</script>