<template>
  <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">

    <div class="modal-container">

    <div class="flex flex-col px-8">
        <p class="m-auto text-xl">Address copied!</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AddressCopiedModal',
}
</script>