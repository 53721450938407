<template>
  <div>
    <audio class="hidden" ref="tidalPlayer" controls :src="track"></audio>
    <div class="flex w-full justify-end items-center" v-if="nowPlayingAlbum">
      <router-link
        :to="{ name: 'home', query: { term: `artist:${nowPlayingAlbum.artist}` }}"
        class="mr-2 mt-px link truncate hidden sm:inline max-w-1/2">{{ nowPlayingAlbum.artist }}</router-link>
      <div v-if="nowPlayingTrack && !nowPlayingAlbum.is_nft" class="mr-2 truncate hidden sm:inline max-w-1/2">"{{ nowPlayingTrack }}"</div>
      <NowPlayingNft />
      <NowPlayingThumbnail :cover="nowPlayingAlbum.front_cover" :id="nowPlayingAlbum.id" />
      <router-link
        :to="{ name: 'release', params: { id: nowPlayingAlbum.id } }"
        class="mr-2 truncate hidden sm:inline max-w-1/2 focus:outline-none border-b-2 border-transparent hover:border-black dark:hover:border-white">{{ nowPlayingAlbum.title }}</router-link>
      <div class="h-6 float-right flex items-center cursor-pointer">
        <div v-if="showLoading" class="spinner">
          <svg
            version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px"
            height="20px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
            <g id="Layer_1" fill="currentColor">
              <g>
                <circle cx="10" cy="2.413" r="1.913"/>
                <circle cx="10" cy="17.587" r="1.913"/>
                <circle cx="2.412" cy="10" r="1.912"/>
                <circle cx="17.588" cy="10" r="1.912"/>
                <circle cx="4.635" cy="4.635" r="1.913"/>
                <circle cx="15.365" cy="15.365" r="1.913"/>
                <circle cx="4.635" cy="15.365" r="1.913"/>
                <circle cx="15.365" cy="4.635" r="1.913"/>
              </g>
            </g>
          </svg>
        </div>
        <button
          v-else-if="showResume"
          @click.stop="resume">
          <svg
            version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px"
            height="20px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
            <g fill="currentColor">
              <polygon points="1.5,1.006 1.5,18.993 18.5,10" />
            </g>
          </svg>
        </button>
        <button
          v-else
          @click.stop="pause">
          <svg
            version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px"
            height="20px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
            <g fill="currentColor">
              <g>
                <rect x="2.167" y="1.006" width="6" height="17.987"/>
                <rect x="11.833" y="1.006" width="6" height="17.987"/>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
    <div v-else-if="showPlay">
      <button
        @click.stop="$emit('play-clicked')"
        class="cursor-pointer link shrink-0">
        Play
      </button>
    </div>
    <div class="hidden md:inline" v-else>Nothing playing</div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import NowPlayingThumbnail from '@/components/NowPlayingThumbnail'
import NowPlayingNft from '@/components/NowPlayingNft'
export default {
  name: 'NowPlayingItem',
  components: {
    NowPlayingThumbnail,
    NowPlayingNft
  },
  watch: {
    playback(val){
      if(val && val.name){
        this.nowPlayingTrack = val.name
      }

    },
    nowPlayingItem(val){
      if(val && val.attributes && val.attributes.name){
        this.nowPlayingTrack = val.attributes.name
      }
    },
    nowPlayingAlbum(val){
      if(this.tidalConnected){
        this.nowPlayingTrack = val.tracks[this.$store.state.tidal.currentIndex].name
      }
    },
    track(track){
      console.log('track changed', track)
      this.$refs.tidalPlayer.play()
    }
  },
  computed: {
    ...mapGetters("spotify", {
      playback: "getPlayback",
      context: "getPlaybackContext"
    }),
    ...mapState('musickit',
      [
        'nowPlayingItem',
        'playbackState',
        'playbackStates',
      ]
    ),
    ...mapState('album', ['album', 'nowPlayingAlbum', 'isPlaying']),
    ...mapState('tidal', ['track', 'playing']),
    ...mapGetters('tidal', ['getTrack']),
    appleMusicConnected(){
      return this.$store.getters['musickit/connected']
    },
    tidalConnected(){
      return this.$store.getters['tidal/connected']
    },
    spotifyConnected(){
      return this.$store.getters['spotify/connected']
    },
    serviceConnected(){
      return this.appleMusicConnected || this.tidalConnected || this.spotifyConnected
    },
    showLoading(){
      if(this.nowPlayingAlbum.is_nft){
        return false
      }

      if(this.appleMusicConnected){
        return [this.playbackStates?.loading, this.playbackStates?.waiting, this.playbackStates?.seeking].indexOf(this.playbackState) !== -1
      }

      return false
    },
    showPlay(){
      if(this.isPlaying){
        return false
      }

      if(this.album){
        if(this.spotifyConnected){
          return (!this.context || this.context.paused) && this.$store.state.spotify.ready
        }

        if(this.appleMusicConnected){
          return [this.playbackStates?.none, this.playbackStates?.paused, this.playbackStates?.stopped].indexOf(this.playbackState) !== -1
        }

        if(this.tidalConnected){
          return true
        }
      }

      return false
    },
    showResume(){
      return !this.isPlaying
    },
    /*track(){
      if(this.nowPlayingAlbum.is_nft){
        return null
      }

      if(this.appleMusicConnected){
        return this.nowPlayingItem.attributes.name
      }else if(this.spotifyConnected){
        return this.playback.name
      }else if(this.tidalConnected){
        return this.nowPlayingAlbum.tracks[this.$store.state.tidal.currentIndex].name
      }else{
        return 'track'
      }
    }*/
  },
  data() {
    return {
      nowPlayingTrack: null
    };
  },
  methods: {
    resume(){
      if(!this.nowPlayingAlbum.is_nft && this.tidalConnected){
        this.$refs.tidalPlayer.play()
        this.$store.commit('album/IS_PLAYING', true)
      }else{
        this.$emit('resume-clicked')
      }
    },
    pause(){
      if(!this.nowPlayingAlbum.is_nft && this.tidalConnected){
        this.$refs.tidalPlayer.pause()
        this.$store.commit('album/IS_PLAYING', false)
      }else{
        this.$emit('pause-clicked')
      }
    },
    trackLoaded(){
      console.log('trackLoaded')

      this.$store.commit('album/IS_PLAYING', true)
      this.$refs.tidalPlayer.play()
    },
    timeUpdate(){
      let duration = this.$refs.tidalPlayer.duration
      let time = this.$refs.tidalPlayer.currentTime
      if(duration - time < 10){
        if(!this.$store.state.tidal.nextTrack && !this.$store.state.tidal.busy){
          this.$store.dispatch('tidal/getNextTrack')
        }
      }
    },
    trackEnded(){
      console.log('track ended', this.$store.state.tidal.nextTrack)
      if(this.$store.state.tidal.nextTrack){
        this.$store.dispatch('tidal/playNextTrack')
      }
    },
  },
  mounted(){
    this.$refs.tidalPlayer.addEventListener('loadedmetadata', this.trackLoaded)
    this.$refs.tidalPlayer.addEventListener('timeupdate', this.timeUpdate)
    this.$refs.tidalPlayer.addEventListener('ended', this.trackEnded)
  },
  beforeDestroy(){
    this.$refs.tidalPlayer.removeEventListener('loadedmetadata', this.trackLoaded)
    this.$refs.tidalPlayer.removeEventListener('timeupdate', this.timeUpdate)
    this.$refs.tidalPlayer.removeEventListener('ended', this.trackEnded)
  }
};
</script>

<style scoped>
@keyframes spinner {
  to {transform: rotate(360deg);}
}
.spinner{
  animation: spinner 2s linear infinite;
}
</style>

