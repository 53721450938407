import CONSTANTS from "@/constants";
import { Rectangle, Polygon } from "@/polygon.js";
import { rotate } from '@/helpers'
import axios from 'axios'

const state = {
  page: 1,
  albums: [],
  allowLoadMore: true,
  album: null,
  landscapeAlbum: null,
  portraitAlbum: null,
  nowPlayingAlbum: null,//window.localStorage.getItem("album") !== null ? JSON.parse(window.localStorage.getItem("album")) : null,
  selectedAlbumId: window.localStorage.getItem("selected_album") || null,
  nft: null,
  owner: null,
  handle: null,
  isPlaying: false
};

const getters = {
  getAlbums: (state) => state.albums,
  getNowPlayingAlbum: (state) => state.nowPlayingAlbum
};

const actions = {
    getAlbums({commit}, offset){

    },
    addToAlbums({commit}, albums){
      commit("ADD_TO_ALBUMS", albums);
    },
    setAlbums({commit}, albums){
      commit("SET_ALBUMS", albums);
    },
    setNowPlayingAlbum({commit}, album) {
      window.localStorage.setItem("album", JSON.stringify(album));
      commit("SET_NOW_PLAYING_ALBUM", album);
    },
    setAlbum({ commit }, album) {
      window.localStorage.setItem("selected_album", album.id);
      document.title = album.artist + ' - ' + album.title + ' • Sleevenote'
      commit("SET_ALBUM", album);
    },
    setLandscapeAlbum({ commit }, album){
      document.title = album.artist + ' - ' + album.title + ' • Sleevenote'
      commit("SET_LANDSCAPE_ALBUM", album);
    },
    setPortraitAlbum({ commit }, album){
      document.title = album.artist + ' - ' + album.title + ' • Sleevenote'
      commit("SET_PORTRAIT_ALBUM", album);
    },
    clearAlbum({commit}){
      document.title = 'Sleevenote'
      commit("CLEAR_ALBUM");
    },
    getAlbumServices({commit, state, dispatch}, id){
      let uri = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/album/services`
      axios.post(uri, {
        id: id
      }).then((response) => {

        let album = response.data.data
        if(state.album.id === album.id){
          dispatch('setAlbum', album)
        }

      })
      .catch((error) => {
        console.log(error)
      });
    },
    getAlbumColors({commit, state, dispatch}, id){
      let uri = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/album/colors`
      axios.post(uri, {
        id: id
      }).then((response) => {
        let album = response.data.album
        if(state.album.id === album.id){
          commit('SET_ALBUM_COLORS', album.colors)
        }
      })
      .catch((error) => {
        console.log(error)
      });
    }
};

const mutations = {
  INCREMENT_PAGE(state){
    state.page = state.page + 1
  },
  ADD_TO_ALBUMS(state, albums){
    state.albums = state.albums.concat(albums)
  },
  SET_ALBUMS(state, albums){
    state.albums = albums;
  },
  SET_NOW_PLAYING_ALBUM(state, album) {
    state.nowPlayingAlbum = album;
  },
  SET_SELECTED_ALBUM_ID(state, id) {
    state.selectedAlbumId = id;
  },
  SET_ALBUM(state, album) {
    let polygons = []
    let rotatedPolygons = []

    if(album.id === '1d47cdc4-566a-4483-a9ba-275afd9bb3c5'){
      album.rotateImages = true
    }

    album.tracks.forEach((track) => {
      if (track.polygon) {
        let poly = new Polygon()
        let rotatedPoly = new Polygon()
        poly.points = []
        rotatedPoly.points = []
        track.polygon.points.forEach((p) => {

          rotatedPoly.points.push(rotate(CONSTANTS.CANVAS_SIZE/2, CONSTANTS.CANVAS_SIZE/2, p.x * CONSTANTS.CANVAS_SIZE, p.y * CONSTANTS.CANVAS_SIZE, 90))

          poly.points.push({
            x: p.x * CONSTANTS.CANVAS_SIZE,
            y: p.y * CONSTANTS.CANVAS_SIZE,
          })
        })

        poly.bIsRectangle = track.polygon.bIsRectangle
        poly.bIsClosed = true
        poly.track = JSON.parse(JSON.stringify(track))

        rotatedPoly.bIsRectangle = track.polygon.bIsRectangle
        rotatedPoly.bIsClosed = true
        rotatedPoly.track = JSON.parse(JSON.stringify(track))

        polygons.push(poly)
        rotatedPolygons.push(rotatedPoly)

        track.polygon = poly
        track.rotatedPolygon = rotatedPoly
      }
    });
    album.polygons = polygons
    album.rotatedPolygons = rotatedPolygons
    album.multi_image = false
    state.album = album;
  },
  SWAP_ALBUM(state, album){
    window.localStorage.setItem("selected_album", album.id);
    state.album = album
  },
  SET_LANDSCAPE_ALBUM(state, album){
    let polygons = []

    album.tracks.forEach((track) => {
      if (track.polygon) {
        let poly = new Polygon()
        poly.points = []
        track.polygon.points.forEach((p) => {
          poly.points.push({
            x: p.x * CONSTANTS.CANVAS_SIZE,
            y: p.y * CONSTANTS.CANVAS_SIZE,
          })
        })

        poly.bIsRectangle = track.polygon.bIsRectangle
        poly.bIsClosed = true
        poly.track = JSON.parse(JSON.stringify(track))

        polygons.push(poly)

        track.polygon = poly
      }
    });
    album.polygons = polygons
    album.multi_image = true
    state.landscapeAlbum = album;
  },
  SET_PORTRAIT_ALBUM(state, album){
    let polygons = []

    album.tracks.forEach((track) => {
      if (track.polygon) {
        let poly = new Polygon()
        poly.points = []
        track.polygon.points.forEach((p) => {
          poly.points.push({
            x: p.x * CONSTANTS.CANVAS_SIZE,
            y: p.y * CONSTANTS.CANVAS_SIZE,
          })
        })

        poly.bIsRectangle = track.polygon.bIsRectangle
        poly.bIsClosed = true
        poly.track = JSON.parse(JSON.stringify(track))

        polygons.push(poly)

        track.polygon = poly
      }
    });
    album.polygons = polygons
    album.multi_image = true
    state.portraitAlbum = album;
  },
  SET_ALBUM_NOT_IN_DB(state, album){
    state.album = album;
  },
  CLEAR_ALBUM(state){
    state.album = null
    state.selectedAlbumId = null
    window.localStorage.removeItem("selected_album");
    window.localStorage.removeItem("album");
  },
  SET_NFT(state, nft){
    state.nft = nft
  },
  SET_OWNER(state, owner){
    state.owner = owner
  },
  SET_OWNER_HANDLE(state, handle){
    state.handle = handle
  },
  SET_ALLOW_LOAD_MORE(state, allow){
    state.allowLoadMore = allow
  },
  SET_ALBUM_COLORS(state, colors){
    state.album.bg_color = colors.bg_color
    state.album.fg_color = colors.fg_color
  },
  IS_PLAYING(state, playing){
    state.isPlaying = playing
  }
};

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default module;
