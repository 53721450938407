<template>
  <div class="h-full">
    <transition name="fade">
      <img :src="src" @load="onLoaded" v-show="loaded" class="w-full h-full object-cover">
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AnimatedImage',
  data() {
    return {
      loaded: false,
    }
  },
  props: {
    src: String,
    thumb: String
  },
  methods: {
    onLoaded() {
      this.loaded = true
      this.$emit('image-loaded')
    }
  },
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
