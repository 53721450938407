<template>
  <div>
    <img class="fixed left-0 top-0 z-0 w-screen h-screen object-cover opacity-50" src="@/assets/about_bg.jpg" />
    <div class="relative z-1 mx-4 mt-16 mb-32 text-mauve dark:text-white font-extrabold max-w-screen-lg">
      <h1 class="mt-4 mb-6 text-3xl xs:text-5xl text-white">This is Sleevenote</h1>
      <div class="mb-8 text-2xl xs:text-3xl">Hey! Thanks for checking us out. We have two quick questions for you:</div>
      <div class="mb-8">
        <div class="mb-4 text-2xl xs:text-3xl">Is having a music collection important to you?</div>
        <div class="mb-4 text-2xl xs:text-3xl">
          <button
            @click="importantYesClicked"
            class="link font-extrabold"
            :class="{ 'opacity-30' : important === false }">Yes</button> /
          <button
            @click="importantNoClicked"
            class="link font-extrabold"
            :class="{ 'opacity-30' : important === true }">No</button>
        </div>
      </div>
      <div v-if="important" class="mb-8">
        <div class="mb-4 text-2xl xs:text-3xl">Do you like the idea of sharing it publicly?</div>
        <div class="mb-4 text-2xl xs:text-3xl">
          <button
            @click="sharingYesClicked"
            class="link font-extrabold"
            :class="{ 'opacity-30' : sharing === false }">Yes</button> /
          <button
            @click="sharingNoClicked"
            class="link font-extrabold"
            :class="{ 'opacity-30' : sharing === true }">No</button>
        </div>
      </div>
      <div v-if="showError" class="mb-4 text-2xl xs:text-3xl leading-snug xs:leading-snug">OK then, you probably won't be interested in what we're building. Have a nice day :)</div>
      <div v-if="important && sharing" class="mb-4 text-2xl xs:text-3xl leading-snug xs:leading-snug">Awesome, we hope you'll like what we're working on. We're still building this so please let us know what you think via the Discord or email links in the burger menu. OK then <button @click="getStarted" class="border-b-4 border-mauve dark:border-white hover:border-transparent dark:hover:border-transparent text-left font-extrabold">CLICK HERE TO GET STARTED</button></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Start',
  data(){
    return {
      important: null,
      sharing: null,
      showError: false
    }
  },
  methods: {
    importantYesClicked(){
      this.important = true
      this.showError = false
      this.sharing = null
    },
    importantNoClicked(){
      this.important = false
      this.showError = true
      this.sharing = null
    },
    sharingYesClicked(){
      this.sharing = true
      this.showError = false
      setTimeout(() => {
        window.scroll({
          top: 1000,
          behavior: 'smooth'
        });
      }, 1)

    },
    sharingNoClicked(){
      this.sharing = false
      this.showError = true
    },
    getStarted(){
      this.$router.replace({ name: 'home' })
    }
  }
};
</script>