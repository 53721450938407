<template>
  <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center" @click.stop="$emit('close')">

    <div class="modal-container">

      <div class="flex flex-col px-4 sm:px-8 leading-loose" @click.stop="$emit('dont-close')">
        <p>This track is an NFT on
          <span>
            <svg class="w-6 inline mb-2" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
              <path fill="currentColor" d="M14.289,20.111V7.581h-3.767H9.235H6.831H5.75H1.512v12.531H14.289z"/>
              <path fill="currentColor" d="M15.302,17.897l3.335-9.114l-3.335-1.156V17.897z"/>
              <path fill="currentColor" d="M22.071,11.005l-2.476-1.862l-3.203,9.012L22.071,11.005z"/>
              <path fill="currentColor" d="M11.524,6.319l-0.213,0.269h0.988L11.524,6.319z"/>
              <path fill="currentColor" d="M10.515,5.969L7.769,5.018l-0.574,1.57h2.829L10.515,5.969z"/>
              <path fill="currentColor" d="M13.139,4.286L12.18,5.494l4.755,1.648L13.139,4.286z"/>
              <path fill="currentColor" d="M11.524,6.319l-1.01-0.35l-0.491,0.619h1.288L11.524,6.319z"/>
            </svg>
          </span> {{ nft.platform }}</p>
        <div class="w-8" v-if="loading">
          <Loader  />
        </div>
        <p class="inline-block truncate" v-else-if="owner">
          Current owner: <a class="cursor-pointer link" :href="`https://beta.catalog.works/id/${owner.id}`" target="_blank">@{{ handle ? handle : owner.id }}</a>
        </p>
        <div class="w-full flex justify-between mt-8">
          <button class="cursor-pointer link text-center shrink-0"  @click.stop="$emit('close')">Close</button>
          <a class="cursor-pointer link text-center shrink-0" :href="nft.url" target="_blank">View on Catalog</a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'NftModal',
  props: {
    loading: Boolean,
    nft: Object,
    owner: Object,
    handle: Object
  },
}
</script>