<template>
  <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">

    <div class="modal-container">

      <div class="w-full h-full flex flex-col justify-center px-8">

        <div v-if="!loggedIn" class="flex flex-col mb-8">
          <div>Already have an account?</div>
          <div><router-link :to="{ name: 'login' }" class="border-b-2 border-black dark:border-white hover:border-transparent dark:hover:border-transparent">Log in</router-link> before connecting your wallet</div>
        </div>

        <div class="flex flex-col">
          <div class="hidden font-extrabold mb-2">Ethereum:</div>
          <button
            @click="$emit('metamask-clicked')"
            class="modal-link">Metamask</button>
          <button
            @click="$emit('wallet-connect-clicked')"
            class="modal-link">Wallet Connect</button>
        </div>

        <button
          class="modal-close cursor-not-allowed"
          @click.stop="$emit('close')">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'WalletModal',
  computed: {
    ...mapGetters('auth', ['user', 'loggedIn'])
  }
}
</script>