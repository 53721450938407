import Vue from 'vue'
import App from './App.vue'
import Loader from '@/components/Loader';

import router from './router'
import store from './store'

import '@/assets/css/tailwind.css'

Vue.config.productionTip = false

Vue.component('Loader', Loader);

window.addEventListener('musickitloaded', async function () {
  console.log('musickitloaded')
  store.dispatch('musickit/init')
})

let musickit = document.createElement('script')
musickit.setAttribute('src', 'https://js-cdn.music.apple.com/musickit/v3/musickit.js')
document.head.appendChild(musickit)

window.onSpotifyWebPlaybackSDKReady = () => {};

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
