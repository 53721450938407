<template>
  <div class="z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center" @click.stop="$emit('close')">

    <div class="modal-container">

      <div class="w-full h-full flex flex-col justify-center px-8">
          <button  v-if="showAppleMusicButton"
                @click.stop="$emit('connect-apple-music')"
                class="modal-link">Connect Apple Music</button>
          <button  v-if="showSpotifyButton"
                @click.stop="$emit('connect-spotify')"
                class="modal-link">Connect Spotify Premium</button>
          <button  v-if="showTidalButton"
                @click.stop="$emit('connect-tidal')"
                class="modal-link">Connect TIDAL HiFi</button>

          <button
            class="modal-close"
            @click.stop="$emit('close')">Close</button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayModal',
  props: {
    album: null,
    showSpotifyButton: {
      type: Boolean,
      default: false
    },
    showAppleMusicButton: {
      type: Boolean,
      default: false
    },
    showTidalButton: {
      type: Boolean,
      default: false
    }
  },
}
</script>