<template>
  <div>
    <div class="mx-4 mt-16">
      <h1 class="text-2xl xs:text-3xl mb-8">Edit Your Profile</h1>

      <div class="text-base xs:text-xl">
        <div v-if="user.username" class="mb-8 space-y-1">
          <div><span class="font-bold">Username</span> @{{ user.username }}</div>
          <div class="text-sm">You can't change this</div>
          <div class="h-3">
            <div v-if="!updatingUsername && usernameUpdated" class="text-sm text-green-500">Username updated</div>
          </div>
        </div>
        <form v-else @submit.prevent="updateUsername" class="mb-8 space-y-1">
          <div class="flex max-w-sm">
            <div class="font-bold">Username</div>
            <input
              type="text"
              autocomplete="none"
              v-model="username"
              :placeholder="user.username"
              class="ml-2 mb-1 w-full bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
          </div>
          <LoadingButton title="Add" :disabled="!username" :loading="updatingUsername" />
          <div class="h-3">
            <div v-if="errors.username && username" class="text-sm text-red-500">{{ errors.username.join(',') }}</div>
          </div>
        </form>

        <div v-if="user.is_label" class="mb-8 space-y-1">
          <div class="font-bold">Profile picture</div>
          <div class="flex items-end">
            <img v-if="user.logo" :src="user.logo" class="w-20 h-20 object-cover rounded-full" />
            <div v-else class="flex-shrink-0 mr-2 w-20 h-20 border-black border-2 border-dashed">
            </div>
            <input
              type="file"
              name="logo"
              ref="logo"
              @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
              accept="image/*"
              class="hidden">
            <LoadingButton
              :title="user.logo ? 'Update' : 'Add'"
              type="button"
              :disabled="false"
              :loading="updatingLogo"
              @click="$refs.logo.click()"
              class="ml-2" />
          </div>
          <div class="h-3">
            <div v-if="errors.logo" class="text-sm text-red-500">{{ errors.logo.join(',') }}</div>
            <div v-else-if="!updatingLogo && logoUpdated" class="text-sm text-green-500">Logo updated</div>
          </div>
        </div>

        <form v-if="user.is_label" @submit.prevent="updateName" class="mb-8 space-y-1">
          <div class="flex max-w-sm">
            <div class="font-bold">Name</div>
            <input
              v-model="name"
              type="text"
              autocomplete="none"
              :placeholder="user.name"
              class="ml-2 mb-1 w-full bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
          </div>
          <LoadingButton :title="user.name ? 'Update' : 'Add'" :disabled="!name"  :loading="updatingName" />
          <div class="h-3">
            <div v-if="errors.name && name" class="text-sm text-red-500">{{ errors.name.join(',') }}</div>
            <div v-else-if="!updatingName && nameUpdated" class="text-sm text-green-500">Name updated</div>
          </div>
        </form>

        <div class="mb-11 space-y-1">
          <div class="font-bold">Wallet</div>
          <div v-if="!user.wallet" class="text-sm">For showing music NFTs and can also be used for signing in.</div>
          <button
            v-if="connected && user.wallet"
            @click="$emit('copy-wallet-address')"
            class="text-base rounded-md px-2 py-0.5 mb-2 text-white dark:text-black bg-green-300 hover:bg-green-400">Connected wallet: {{ truncatedWallet }}</button>
          <div v-if="!connected && user.wallet">
            <button
              @click="$emit('copy-wallet-address')"
              class="text-base rounded-md px-2 py-0.5 mb-2 text-white dark:text-black bg-gray-300 hover:bg-gray-400">Associated wallet: {{ truncatedWallet }}</button>
            <div class="text-sm">This wallet is associated with your account but not connected</div>
          </div>
          <button
            v-if="!connected"
            @click.prevent="$emit('connect-wallet')"
            class="text-base wallet-connect-button">Connect wallet</button>
        </div>

        <form @submit.prevent="updateEmail" class="mb-8 space-y-1">
          <div class="flex max-w-sm">
            <div class="font-bold">Email</div>
            <input
              type="text"
              autocomplete="none"
              v-model="email"
              class="ml-2 mb-1 w-full bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
          </div>
          <div v-if="!user.email" class="text-sm">Add an email to keep your data without a wallet.</div>
          <LoadingButton :title="user.email ? 'Update' : 'Add'" :disabled="!email" :loading="updatingEmail" />
          <div class="h-3">
            <div v-if="errors.email && email" class="text-sm text-red-500">{{ errors.email.join(',') }}</div>
            <div v-else-if="!updatingEmail && emailUpdated" class="text-sm text-green-500">Email updated</div>
          </div>
        </form>

        <form v-if="user.username" @submit.prevent="updatePassword" class="mb-8 space-y-1">
          <div class="flex max-w-sm">
            <div class="font-bold">Password</div>
            <input
              v-model="password"
              type="password"
              required
              autocomplete="new-password"
              class="ml-2 mb-1 w-full bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
          </div>
          <LoadingButton title="Update" :disabled="!password" :loading="updatingPassword" />
          <div class="h-3">
            <div v-if="errors.password && password" class="text-sm text-red-500">{{ errors.password.join(',') }}</div>
            <div v-else-if="!updatingPassword && passwordUpdated" class="text-sm text-green-500">Password updated</div>
          </div>
        </form>

        <div class="mb-16 mt-20 space-y-1">
          <div class="font-bold">Delete account</div>
          <div class="text-sm">Click <button @click="$emit('delete-account-clicked')" class="text-red-300 hover:text-red-400">here</button> to delete your account. All your data will be removed!</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import LoadingButton from '@/components/LoadingButton'

export default {
  components: {
    LoadingButton
  },
  data(){
    return{
      name: null,
      username: null,
      email: null,
      password: null,
      errors: [],
      updatingEmail: false,
      updatingPassword: false,
      updatingUsername: false,
      updatingName: false,
      updatingLogo: false,
      emailUpdated: false,
      passwordUpdated: false,
      usernameUpdated: false,
      nameUpdated: false,
      logoUpdated: false,
      loading: false,
      enableValidate: false
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('web3', ['connected']),
    allowUsernameUpdate(){
      return !this.user.username
    },
    canDissociateWallet(){
      return this.user.email && this.user.username
    },
    truncatedWallet(){
      return this.user.wallet.substring(0, 6) + '...'
    }
  },
  watch: {
    user(value){
      this.email = value.email ? value.email : null
      this.name = value.name ? value.name : null
      setTimeout(() => {
        this.enableValidate = true
      }, 500);
    },
    email(value) {
      if(!this.enableValidate)return
      this.validating = true
      this.validate(`email=${value}`)
    },
    username(value) {
      if(!this.enableValidate)return
      this.validating = true
      this.validate(`username=${value}`)
    },
  },
  methods: {
    updateEmail(){
      this.updatingEmail = true
      this.emailUpdated = false
      this.errors = []

      axios.post(`${process.env.VUE_APP_API_URL}/listener/update`, {
        email: this.email
      }, {
        headers: {
          Authorization: "Bearer " + this.$store.state.auth.token
        }
      })
      .then((response) => {
        this.emailUpdated = true
        const user = response.data.user
        this.$store.commit('auth/SET_USER', user)
      })
      .catch((error) => {
        console.log(error)
        this.errors = error.response.data.message
      })
      .finally(() => {
        this.updatingEmail = false
      })
    },
    updatePassword(){
      this.updatingPassword = true
      this.passwordUpdated = false
      this.errors = []

      axios.post(`${process.env.VUE_APP_API_URL}/listener/update`, {
        password: this.password
      }, {
        headers: {
          Authorization: "Bearer " + this.$store.state.auth.token
        }
      })
      .then((response) => {
        this.passwordUpdated = true
        const user = response.data.user
        this.$store.commit('auth/SET_USER', user)
      })
      .catch((error) => {
        console.log(error)
        this.errors = error.response.data.message
      })
      .finally(() => {
        this.updatingPassword = false
      })
    },
    updateUsername(){
      this.updateUsername = true
      this.usernameUpdated = false
      this.errors = []

      axios.post(`${process.env.VUE_APP_API_URL}/listener/update`, {
        username: this.username
      }, {
        headers: {
          Authorization: "Bearer " + this.$store.state.auth.token
        }
      })
      .then((response) => {
        this.usernameUpdated = true
        const user = response.data.user
        this.$store.commit('auth/SET_USER', user)
        //this.$router.push({ name: 'usercollection', params: { username: user.username } })
      })
      .catch((error) => {
        console.log(error)
        this.errors = error.response.data.message
      })
      .finally(() => {
        this.updatingUsername = false
      })
    },
    updateName(){
      this.updatingName = true
      this.nameUpdated = false
      this.errors = []

      axios.post(`${process.env.VUE_APP_API_URL}/listener/update`, {
        name: this.name
      }, {
        headers: {
          Authorization: "Bearer " + this.$store.state.auth.token
        }
      })
      .then((response) => {
        this.nameUpdated = true
        const user = response.data.user
        this.$store.commit('auth/SET_USER', user)
      })
      .catch((error) => {
        console.log(error)
        this.errors = error.response.data.message
      })
      .finally(() => {
        this.updatingName = false
      })
    },
    updateLogo(formData){
      this.updatingLogo = true
      this.logoUpdated = false
      this.errors = []

      axios.post(`${process.env.VUE_APP_API_URL}/listener/update`, formData, {
        headers: {
          Authorization: "Bearer " + this.$store.state.auth.token
        }
      })
      .then((response) => {
        this.logoUpdated = true
        const user = response.data.user
        this.$store.commit('auth/SET_USER', user)
      })
      .catch((error) => {
        console.log(error)
        this.errors = error.response.data.message
      })
      .finally(() => {
        this.updatingLogo = false
      })
    },
    validate(param) {
      this.validating = true
      axios.get(`${process.env.VUE_APP_API_URL}/listener/validate?${param}`)
      .then((response) => {
        this.errors = []
      })
      .catch((error) => {
        this.errors = error.response.data.message
      })
      .finally(() => {
        this.validating = false
      })
    },
    filesChange(fieldName, fileList) {
        const formData = new FormData();

        if (!fileList.length) return;

        Array.from(Array(fileList.length).keys()).map(x => {
          formData.append(fieldName, fileList[x], fileList[x].name);
        });

        this.updateLogo(formData);
    }
  },
  mounted(){
    if(this.user){
      this.email = this.user.email ? this.user.email : null
      this.name = this.user.name ? this.user.name : null
      setTimeout(() => {
        this.enableValidate = true
      }, 500);
    }

  },
  created() {
  }
};
</script>
