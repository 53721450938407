import axios from "axios";

const state = {
  collection: [],
  prev_username: null,
  poll_wallet: true
}

const getters = {

}

const mutations = {
  SET_COLLECTION(state, collection){
    state.collection = collection
  },
  SET_PREV_USERNAME(state, username){
    state.prev_username = username
  },
  SET_POLL_WALLET(state, poll){
    state.poll_wallet = poll
  }
}

const actions = {
  getUserCollection(context){
    axios.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/collection/me`, {
      headers: {
          Authorization: "Bearer " + context.rootState.auth.token
        }
      })
    .then((response) => {
      context.commit('SET_COLLECTION', response.data.collection)
    })
    .catch((error) => {
      console.log(error)
    })
  },
  add(context, id){
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/collection/add`, {
          album: id,
        }, {
          headers: {
            Authorization: "Bearer " + context.rootState.auth.token
          }
        })
        .then((response) => {
          console.log(response);
          context.commit('SET_COLLECTION', response.data.collection)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
      });
  },
  removeFromCollection(context, id){
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/collection/remove`, {
          album: id
        }, {
          headers: {
            Authorization: "Bearer " + context.rootState.auth.token
          }
        })
        .then((response) => {
          console.log(response);
          context.commit('SET_COLLECTION', response.data.collection)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  pollWallet({ commit }){
    commit('SET_POLL_WALLET', false)
    setTimeout(() => {
      commit('SET_POLL_WALLET', true)
    }, 1000 * 60 * 60) //only poll a wallet each hour
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};