<template>
  <div v-if="album">
    <div>
      <AlbumScroller
        v-show="showAlbum"
        ref="albumScroller"
        :album="album" />
      <div
        v-if="loading"
        class="fixed left-0 top-0 w-full h-screen flex items-center justify-center">
        <div class="w-1/4 md:w-1/12">
          <Loader />
        </div>
      </div>
    </div>

    <transition name="fade">
      <NftModal v-if="showNftModal"
                :nft="nft"
                :loading="loadingOwner"
                :owner="owner"
                :handle="handle"
                @close="showNftModal = false" />
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import AlbumScroller from '@/components/AlbumScroller'
import NftModal from '@/components/modals/NftModal'
import { playTrack, resume } from '@/player'
import { GetOwner, GetUserHandle } from '@/api/nfts/catalog'
import { AudioFromTrack } from '@/api/nfts/sound'

export default {
  name: 'Release',
  data(){
    return{
      loading: true,
      showAlbum: false,
      showNftModal: false,
      nft: null,
      loadingOwner: true,
      owner: null,
      handle: null,
      player: {}
    }
  },
  components: {
    AlbumScroller,
    NftModal,
  },
  computed: {
    ...mapState('album', ['album', 'nowPlayingAlbum', 'isPlaying']),
    ...mapState('musickit', ['storefront']),
    appleMusicConnected(){
      return this.$store.getters['musickit/connected']
    },
    tidalConnected(){
      return this.$store.getters['tidal/connected']
    },
    spotifyConnected(){
      return this.$store.getters['spotify/connected']
    },
    nothingConnected(){
      return !this.appleMusicConnected && !this.spotifyConnected && !this.tidalConnected
    },
    serviceConnected(){
      return this.appleMusicConnected || this.tidalConnected || this.spotifyConnected
    }
  },
  watch: {
    '$route.params.id': function() {
      this.$store.dispatch('album/clearAlbum')
      this.loading = true
      this.loadAlbum(this.$route.params.id)
    },
    '$store.state.musickit.storefront': function() {
      this.$store.dispatch('album/clearAlbum')
      this.loading = true
      this.loadAlbum(this.$route.params.id)
    }
  },
  beforeCreate: function() {
    if(window.innerWidth > window.innerHeight){
      document.body.classList.add('landscape')
    }
  },
  mounted(){
    //let vh = window.innerHeight * 0.01
    //document.documentElement.style.setProperty('--vh', `${vh}px`)
    let id = this.$route.params.id
    if(this.$route.name === 'blackmidicavalcade'){
      id = 'b6cdaf6b-323c-444e-b056-4255ea643c1f'
    }
    //this.$store.commit('tidal/INIT')

    this.loadAlbum(id)

    window.addEventListener('resize', this.windowResized)
    window.addEventListener('orientationchange', this.windowRotated)
    this.$root.$on('play-track', this.playTrack)

    document.addEventListener('keyup', this.keyPressed)

    /*if('mediaSession' in navigator) {

        navigator.mediaSession.setActionHandler('play', this.togglePlayback);
        navigator.mediaSession.setActionHandler('pause', this.togglePlayback);
        navigator.mediaSession.setActionHandler('stop', this.stopTrack);
        navigator.mediaSession.setActionHandler('previoustrack', this.prevTrack);
        navigator.mediaSession.setActionHandler('nexttrack', this.nextTrack);

    }*/
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.windowResized)
    window.removeEventListener('orientationchange', this.windowRotated)
    this.$root.$off('play-track', this.playTrack)
    document.body.classList.remove('landscape')

    document.removeEventListener('keyup', this.keyPressed)
  },
  methods: {
    keyPressed(event){
      if (event.code === 'Space') {
        if(this.nowPlayingAlbum){
          if(this.isPlaying){
            this.pause()
          }else{
            this.resume()
          }
        }
      }
    },
    resume(){
      if(!this.nowPlayingAlbum.is_nft && this.tidalConnected){
        this.$refs.tidalPlayer.play()
        this.$store.commit('album/IS_PLAYING', true)
      }else{
        this.$emit('resume-clicked')
      }
    },
    pause(){
      if(!this.nowPlayingAlbum.is_nft && this.tidalConnected){
        this.$refs.tidalPlayer.pause()
        this.$store.commit('album/IS_PLAYING', false)
      }else{
        this.$emit('pause-clicked')
      }
    },
    windowResized(){
      if(this.$store.state.album.multi_image){
        if(window.innerWidth < window.innerHeight){
          this.$store.commit('album/SWAP_ALBUM', this.$store.state.album.portraitAlbum)
        }else{
          this.$store.commit('album/SWAP_ALBUM', this.$store.state.album.landscapeAlbum)
        }
      }

      if(window.innerWidth > window.innerHeight){
        document.body.classList.add('landscape')
      }else{
        document.body.classList.remove('landscape')
      }

      this.checkSwapOrientation()
    },
    windowRotated(){
      this.checkSwapOrientation()
    },
    checkSwapOrientation(){
      if(this.$store.state.album.multi_image){
        if(window.innerWidth < window.innerHeight){
          this.$store.commit('album/SWAP_ALBUM', this.$store.state.album.portraitAlbum)
        }else{
          this.$store.commit('album/SWAP_ALBUM', this.$store.state.album.landscapeAlbum)
        }
      }
    },
    playTrack(track, index){

      this.$refs.albumScroller.showNftIcon = false

      let trackIndex = index
      if(track.number){
        trackIndex = track.number-1
      }

      //multi-disc support
      if(track.disc > 1){
        let tracks = this.album.tracks;
        trackIndex = tracks.findIndex(t => t.name === track.name);
      }

      if(this.album.is_nft){
        playTrack(this.album, trackIndex)
        return
      }

      if(track.nft){
        this.loadingOwner = true
        this.showNftModal = true
        this.nft = track.nft
        this.$store.commit('album/SET_NFT', this.nft)
        this.getOwner(this.nft)
      }else{
        this.showNftModal = false
        this.nft = null
        this.owner = null
        this.handle = null
        this.$store.commit('album/SET_NFT', this.nft)
        this.$store.commit('album/SET_OWNER', this.owner)
        this.$store.commit('album/SET_OWNER_HANDLE', this.handle)
      }

      if(this.serviceConnected){
        playTrack(this.album, trackIndex)
        return
      }

      if(track.nft){
        return
      }

      if(this.nothingConnected){
        this.$emit('show-connect-modal');
        return
      }
    },
    loadAlbum(id) {
      this.loading = true

      const dpr = window.devicePixelRatio
      let size = Math.floor(window.innerHeight)
      if(window.innerHeight > window.innerWidth){
        size = Math.floor(window.innerWidth)
      }
      let roundedSize = Math.ceil(size/100)*100


      let uri = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/album?id=${id}&size=${roundedSize}&dpr=${dpr}`
      if(this.tidalConnected){
        uri = uri + `&locale=${this.$store.state.tidal.session.countryCode}`
      }else if(this.appleMusicConnected){
        uri = uri + `&locale=${this.$store.state.musickit.storefront}`
      }

      axios.get(uri)
        .then(async (response) => {

          let images = []

          if(Array.isArray(response.data)){
            let landscapeAlbum = response.data[0]
            let portraitAlbum = response.data[1]
            this.$store.dispatch('album/setLandscapeAlbum', landscapeAlbum)
            this.$store.dispatch('album/setPortraitAlbum', portraitAlbum)

            if(window.innerWidth < window.innerHeight){
              images = [portraitAlbum.front_cover, portraitAlbum.back_cover, ...portraitAlbum.artwork]
              this.$store.commit('album/SWAP_ALBUM', this.$store.state.album.portraitAlbum)
            }else{
              images = [landscapeAlbum.front_cover, landscapeAlbum.back_cover, ...landscapeAlbum.artwork]
              this.$store.commit('album/SWAP_ALBUM', this.$store.state.album.landscapeAlbum)
            }
          }else{
            images = [response.data.data.front_cover]
            if(response.data.data.back_cover){
              images.push(response.data.data.back_cover)
            }
            if(response.data.data.artwork && response.data.data.artwork.length > 0){
              images.push(...response.data.data.artwork)
            }
            this.$store.dispatch('album/setAlbum', response.data.data)
          }

          let count = 0
          images.forEach(image => {
            let img = new Image()
            img.onload = () => {
              count = count+1
              if(count === images.length){
                this.loading = false
              }
            }
            img.src = image
          })
          this.showAlbum = true

          if(!this.album.bg_color){
            this.getAlbumColor(id)
          }

          if(!this.album.has_services && !this.album.is_nft){

            this.getAlbumServices(id)

          }else if(this.tidalConnected && !this.album.is_nft){

            this.$store.dispatch('tidal/getAlbumTracks', { album_id: this.album.tidal_id })

          }else if(this.album.is_nft && this.album.nft_metadata.platform === 'sound' && !this.album.nft_metadata.audio_url){
            let audio_url = await AudioFromTrack(this.album.nft_metadata.track_id)
            this.album.tracks[0].media_url = audio_url.data?.data?.audioFromTrack?.audio?.url
          }

        })
        .catch((error) => {
          console.log(error)
      });
    },
    getAlbumServices(id){
      this.$store.dispatch('album/getAlbumServices', id)
    },
    getAlbumColor(id){
      this.$store.dispatch('album/getAlbumColors', id)
    },
    scrollTo(target){
      target.scrollIntoView({ block: 'center',  behavior: 'smooth' })
    },
    async getOwner(nft){
      this.loadingOwner = true
      let result = await GetOwner(nft.id)
      this.owner = result.data.data.media.owner
      this.$store.commit('album/SET_OWNER', this.owner)
      let resp = await GetUserHandle(this.owner.id)
      if(resp.data.data.catalog_users.length > 0){
        this.handle = resp.data.data.catalog_users[0].handle
      }else{
        this.handle = null
      }
      this.$store.commit('album/SET_OWNER_HANDLE', this.handle)
      this.loadingOwner = false
    },
  }
}
</script>