<template>
  <div>
    <div class="mx-4 mt-16">
      <h1 class="text-2xl xs:text-3xl mb-10">Send password reset</h1>
      <form @submit.prevent="submit" class="text-base xs:text-xl">

        <div class="mb-10 flex flex-wrap">
          <div class="w-full flex justify-between max-w-md">
            <label class="mr-2 w-20 xs:w-24">Email</label>
            <input
              v-model="email"
              type="text"
              required
              class="flex-grow bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
            </div>
          <div
            v-if="errors"
            class="text-sm xs:text-lg text-red-500 w-full max-w-md">
            {{ errors }}
          </div>
        </div>

        <div>
          <button
            type="submit"
            :disabled="loading"
            class="button w-64 h-12 mb-4"
            :class="{'cursor-not-allowed': loading, 'hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black': !loading}">
            <span v-if="!loading">GO</span>
            <span v-else>
              <Loader></Loader>
            </span>
          </button>
        </div>

        <div v-if="!loading && emailSent" class="text-sm xs:text-lg text-green-500 w-full max-w-md">
          Email with instructions sent
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ForgottenPassword',
  data(){
    return{
      email: null,
      errors: null,
      loading: false,
      emailSent: false
    }
  },
  methods: {
    submit(){
      this.loading = true;
      axios.post(`${process.env.VUE_APP_API_URL}/listener/forgot-password`, {
        email: this.email,
      })
      .then((response) => {
        this.emailSent = true
        this.errors = null
      })
      .catch((error) => {
        this.errors = error.response.data.errors
        this.emailSent = false
      })
      .finally(() => {
        this.loading = false
      })
    }
  },
  mounted(){
  },
  created() {
  }
};
</script>
