<template>
  <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center" @click.stop="$emit('close')">

    <div class="modal-container">

    <div class="flex flex-col px-8">
        <button
          @click="$emit('copy-link')"
          class="modal-link">Copy link</button>

        <button
          class="modal-close"
          @click.stop="$emit('close')">Close</button>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'YourCollectionModal',
  computed: {
    ...mapState('auth', ['user'])
  }
}
</script>