<template>
  <div class="flex items-center">
    <img v-if="logo" :src="logo" class="w-11 sm:w-14 h-11 sm:h-14 object-cover rounded-full" />
    <div v-if="name"
      class="flex-auto font-bold text-2xl leading-6"
      :class="{ 'ml-2' : logo }"><span>{{ name }}</span> <span class="font-bold self-start align-top text-xs sm:text-sm fill-current text-verified">✓Label</span></div>

  </div>
</template>

<script>
export default {
  props: {
    logo: {
       type: String,
        default: ''
    },
    name: {
      type: String,
      default: ''
    }
  }
}
</script>