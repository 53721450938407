<template>
  <div>
    <div class="my-12">
      <AlbumSearch ref="albumSearch" />
      <div v-if="loadingCollection" class="ml-4 flex items-center">
        <div class="w-24">
          <Loader />
        </div>
      </div>
      <div
        v-else-if="error"
        class="mx-4 mt-4 mb-4 text-2xl xs:text-3xl text-red-400">{{ error }}</div>
      <div
        v-else
        class="mb-10">
        <VerifiedLabel
          v-if="user && user.is_label"
          :name="user.name"
          :logo="user.logo"
          :class="{ 'm-4' : user.name || user.logo }" />
        <div class="m-4 flex justify-between items-center">
          <div class="min-w-0 w-full">
            <button
              class="text-left text-xl xs:text-2xl text-black dark:text-white hover:underline dark:hover:underline cursor-pointer"
              @click="$emit('your-collection-clicked')">@{{ truncatedUsername }}<span v-if="user && !user.is_label">'s collection</span></button><router-link v-if="loggedIn && !authUser.username" :to="{ name : 'profile' }" class="ml-1 hover:opacity-50 text-2xl transform rotate-45">✎</router-link>
          </div>
          <div class="flex-shrink-0">
            <ReorderButton
              title="Re-order"
              v-if="allowDrag"
              v-model="enableDrag" />
          </div>
        </div>
        <div
          v-if="!loadingCollection && collection.length === 0"
          class="mx-4 text-2xl xs:text-3xl text-pink-400">Nothing here yet!</div>
        <AlbumGrid
          :albums="collection"
          :dragEnabled="enableDrag"
          @drag-complete="dragComplete" />
        <div v-if="loadingNFTs" class="ml-4 -mt-8 flex items-center">
          <div class="w-24">
            <Loader />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'
import AlbumSearch from '@/components/AlbumSearch'
import AlbumGrid from '@/components/AlbumGrid'
import ReorderButton from '@/components/ReorderButton'
import VerifiedLabel from '@/components/VerifiedLabel'
import { GetCollectionByUserId, GetTracksByNFTIDs } from '@/api/nfts/catalog'
import { GetUserProfile, GetUserNfts } from '@/api/nfts/sound'

export default {
  name: 'UserCollection',
  components: {
    AlbumSearch,
    AlbumGrid,
    ReorderButton,
    VerifiedLabel,
  },
  data(){
    return {
      collection: [],
      user: null,
      ownedTokens: [],
      error: null,
      search: '',
      loadingCollection: false,
      loadingNFTs: false,
      enableDrag: false,
    }
  },
  watch: {
    "$route.params.username": function() {
      this.loadData()
    },
  },
  computed: {
    ...mapState('auth', {
      'authUser': 'user'
    }),
    ...mapGetters('auth', {
      loggedIn: 'loggedIn',
    }),
    allowDrag(){
      return this.loggedIn &&
      (this.$route.params.username === this.authUser.username || this.$route.params.username === this.authUser.wallet) && this.collection.length > 0
    },
    isUsersCollectionPage(){
      return this.$route.params.username === this.authUser.username || this.$route.params.username === this.authUser.wallet
    },
    truncatedUsername(){
      if(this.$route.params.username.length > 30){
        return this.$route.params.username.substring(0, 6) + '...'
      }else{
        return this.$route.params.username
      }

    }
  },
  mounted(){
    this.loadData()
  },
  methods: {
    async loadData(){
      this.loadingCollection = true

      //load SN collection
      await this.loadCollection()

      if(!this.user){
        this.loadingCollection = false
        return
      }

      //if user has wallet then load NFTs
      //let wallet = await this.getUserWallet()

      if(!this.user.wallet){
        this.loadingCollection = false
        return
      }

      this.loadingCollection = false

      await this.checkOwnership(this.user.wallet)

      this.collection.forEach(release => {
        if(this.ownsRelease(release)){
          Vue.set(release, 'owned_by_user', true)
        }else{
          Vue.set(release, 'owned_by_user', false)
        }
      })
    },
    async checkOwnership(wallet){
      this.loadingNFTs = true
      this.ownedTokens = await this.loadTokensFromWallet(wallet)

      //if logged in
      //if(this.loggedIn && wallet === this.authUser.wallet){
        //save NFTs to SN collection
        await this.saveTokensToCollection(this.ownedTokens)
        //if NFTs have been saved then reload collection
        await this.loadCollection()
      //}

      this.loadingNFTs = false
    },
    async createUser(){

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/listener/connect`, {
          wallet: this.$route.params.username
        })

        const token = response.data.token
        const user = response.data.user
        this.$store.commit('auth/SET_TOKEN', token)
        this.$store.commit('auth/SET_USER', user)

      } catch(err) {
        console.log(err)
      }

    },
    ownsRelease(release){
      if(!release.is_nft) return false
      return this.ownedTokens.filter(t => t.id === release.nft_metadata.id).length > 0
    },
    async loadCollection(){
      //this.loadingCollection = true
      let username = this.$route.params.username

      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/collection/${username}?size=250`)
        this.collection = response.data.collection
        this.user = response.data.user
        this.error = null
        this.$store.commit('collection/SET_PREV_USERNAME', username)
        document.title = `@${username}'s collection on Sleevenote`

      if(this.loggedIn && this.isUsersCollectionPage){
          this.$store.commit('collection/SET_COLLECTION', JSON.parse(JSON.stringify(this.collection)))
        }
      } catch(err) {
        this.error = "User not found"
        this.loadingCollection = false
      } finally {
        //this.loadingCollection = false
      }

    },
    async loadTokensFromWallet(address){
      let catalogNFTs = await this.loadCatalogNFTs(address)

      let soundNFTs = await this.loadSoundNFTs(address)

      return catalogNFTs.concat(soundNFTs)
    },
    async loadCatalogNFTs(address){
      try{
        let nfts = []
        let catalogIds = await GetCollectionByUserId(address)
        catalogIds = catalogIds.data.data.Token.map(r => r.tokenId)
        let catalogNFTs = await GetTracksByNFTIDs(catalogIds)

        if(!catalogNFTs.data.data) return nfts

        catalogNFTs.data.data.tracks.forEach(catalogNFT => {
          let nft = {
            id: catalogNFT.id,
            artist: catalogNFT.artist.name,
            title: catalogNFT.title,
            audio: `https://ipfs.io/ipfs/${catalogNFT.ipfs_hash_lossy_audio}`,
            front_cover: `https://ipfs.io/ipfs/${catalogNFT.ipfs_hash_lossy_artwork}`,
            is_nft: true,
            owner: address,
            nft_metadata: {
              id: catalogNFT.id,
              platform_id: catalogNFT.nft_id,
              platform: 'catalog',
              url: `https://beta.catalog.works/${catalogNFT.artist.handle}/${catalogNFT.short_url}`,
              contract_address: catalogNFT.contract_address,
              owner_wallet: address
              //purchased_at: catalogIds.data.data.Token.find(t => t.tokenId === catalogNFT.nft_id).transferEvents.find(te => te.to === address).map(te => te.blockTimestamp)
            }
          }
          nfts.push(nft)
        })
        return nfts
      }catch{
        return []
      }
    },
    async loadSoundNFTs(address){
      let user = await GetUserProfile(address)

      console.log(user.data.data.userByAddress.id)
      let edges = []
      let hasNextPage = true
      let after = null
      while(hasNextPage){
        let resp = await GetUserNfts(user.data.data.userByAddress.id, after)
        edges.push(...resp.data.data.user.nftsPaginated.edges)
        after = resp.data.data.user.nftsPaginated.pageInfo.endCursor
        hasNextPage = resp.data.data.user.nftsPaginated.pageInfo.hasNextPage
      }

      console.log(edges)

      let nfts = []

      for (const edge of edges) {

        const token = edge.node

        let nft = {
            id: token.id,
            artist: token.release.artist.name,
            title: `${token.release.title} #${token.serialNumber}`,
            //audio: audio_url.data?.data?.audioFromTrack?.audio?.url,
            front_cover: token.release.coverImage.url,
            is_nft: true,
            owner: address,
            nft_metadata: {
              id: token.id,
              platform_id: token.id,
              track_id: token.release.track.id,
              platform: 'sound',
              serialNumber: token.serialNumber,
              soundHandle: token.release.artist.soundHandle,
              releaseSlug: token.release.titleSlug,
              url: `https://www.sound.xyz/${token.release.artist.soundHandle}/${token.release.titleSlug}`,
              mintInfoId: token.id,
              owner_wallet: address
            }
          }
          nfts.push(nft)
      }

      return nfts//.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id && t.id===v.id))===i)
    },
    async saveTokensToCollection(tokens){
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/nfts/create`, {
          tokens: tokens
        })
        return response
      } catch(err) {
        return null
      }
    },
    dragComplete(albums){
      this.loadingCollection = true
      axios.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/collection/reorder?size=200`, {
        collection: albums
      }, {
        headers: {
          Authorization: "Bearer " + this.$store.state.auth.token
        }
      })
        .then((response) => {
          this.collection = albums
          //this.collection = response.data.collection
          this.error = null

        })
        .catch((error) => {
          console.log(error)
          this.error = "User not found"
        })
        .finally(() => {
          this.loadingCollection = false
        })
    },
    clearSearch(){
      this.$refs.albumSearch.clearTerm()
    }

  }
}
</script>