<template>
  <div class="text-black dark:text-white overflow-hidden">

    <Header
      @signup-login-clicked="showSignUpLoginModal = !showSignUpLoginModal"
      @burger-clicked="showLogoutModal = !showLogoutModal"
      @open-clicked="showOpenModal = !showOpenModal"
      @your-collection-clicked="showYourCollectionModal = !showYourCollectionModal"
      @others-collection-clicked="showCollectionModal = !showCollectionModal"
      @remove-from-collection-clicked="showRemoveFromCollectionModal = !showRemoveFromCollectionModal"
      @remove-from-library="showRemoveModal = !showRemoveModal"
      @connect-wallet="connectWallet"
      @clear-search="clearSearch" />

    <router-view
      ref="routerView"
      @show-connect-modal="showConnectModal = true"
      @your-collection-clicked="showYourCollectionModal = !showYourCollectionModal"
      @delete-account-clicked="showDeleteAccountModal = !showDeleteAccountModal"
      @connect-wallet="connectWallet"
      @copy-wallet-address="copyWalletAddress"
      @pause-clicked="pauseClicked"
      @resume-clicked="resumeClicked" />

    <Footer
      @connect-clicked="showConnectModal = true"
      @auth-apple-music="authAppleMusic"
      @auth-spotify="authSpotify"
      @auth-tidal="authTidal"
      @play-clicked="playClicked"
      @pause-clicked="pauseClicked"
      @resume-clicked="resumeClicked"
      @connected="serviceHasBeenConnected" />

    <transition name="fade">
      <ConnectModal
        v-if="showConnectModal"
        :album="album"
        :showSpotifyButton="showSpotifyButton"
        :showAppleMusicButton="showAppleMusicButton"
        :showTidalButton="showTidalButton"
        @connect-apple-music="authAppleMusic"
        @connect-spotify="authSpotify"
        @connect-tidal="authTidal"
        @close="showConnectModal = false" />

      <SignUpLoginModal
        v-if="showSignUpLoginModal"
        @close="showSignUpLoginModal = false"
        @connect-wallet="connectWallet" />

      <LogoutModal
        v-if="showLogoutModal"
        @close="showLogoutModal = false" />

      <OpenModal
        v-if="showOpenModal"
        :album="album"
        @close="showOpenModal = false"
        @play-clicked="playClicked" />

      <YourCollectionModal
        v-if="showYourCollectionModal"
        @close="showYourCollectionModal = false"
        @copy-link="copyLink" />

      <CollectionModal
        v-if="showCollectionModal"
        @close="showCollectionModal = false"
        @copy-link="copyLink" />

      <RemoveFromCollectionModal
        v-if="showRemoveModal"
        @remove="remove"
        @close="showRemoveModal = false"
        @copy-link="copyLink" />

      <DeleteAccountModal
        v-if="showDeleteAccountModal"
        @close="showDeleteAccountModal = false" />

      <WalletModal
        v-if="showWalletModal"
        @metamask-clicked="connectMetamask"
        @wallet-connect-clicked="connectWalletConnect"
        @close="showWalletModal = false" />

    </transition>

    <transition name="fade">
      <LinkCopiedModal v-if="showLinkCopiedModal" />

      <AddressCopiedModal v-if="showAddressCopiedModal" />

      <AddedToCollection v-if="showAddedModal" />

      <ConnectedModal v-if="showConnectedModal" />
    </transition>

  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters, mapActions } from 'vuex'
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import ConnectModal from '@/components/modals/ConnectModal'
import SignUpLoginModal from '@/components/modals/SignUpLoginModal'
import LogoutModal from '@/components/modals/LogoutModal'
import OpenModal from '@/components/modals/OpenModal'
import YourCollectionModal from '@/components/modals/YourCollectionModal'
import CollectionModal from '@/components/modals/CollectionModal'
import LinkCopiedModal from '@/components/modals/LinkCopiedModal'
import AddressCopiedModal from '@/components/modals/AddressCopiedModal'
import RemoveFromCollectionModal from '@/components/modals/RemoveFromCollectionModal'
import AddedToCollection from '@/components/modals/AddedToCollection'
import DeleteAccountModal from '@/components/modals/DeleteAccountModal'
import ConnectedModal from '@/components/modals/ConnectedModal'
import WalletModal from '@/components/modals/WalletModal'
import { play, pause, resume } from '@/player'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    ConnectModal,
    SignUpLoginModal,
    LogoutModal,
    OpenModal,
    YourCollectionModal,
    CollectionModal,
    LinkCopiedModal,
    RemoveFromCollectionModal,
    AddedToCollection,
    DeleteAccountModal,
    ConnectedModal,
    WalletModal,
    AddressCopiedModal,
  },
  data(){
    return {
      showConnectModal: false,
      showSignUpLoginModal: false,
      showLogoutModal: false,
      showOpenModal: false,
      showYourCollectionModal: false,
      showCollectionModal: false,
      showLinkCopiedModal: false,
      showRemoveModal: false,
      showAddedModal: false,
      showDeleteAccountModal: false,
      showConnectedModal: false,
      showWalletModal: false,
      connector: null,
      showAddressCopiedModal: false,
      windowHasFocus: false
    }
  },
  watch:{
    $route(to, from){
      this.hideModals()
    },
    address(val){
      if(val){
        this.walletConnected()
      }
    }
  },
  computed: {
    ...mapState('web3', [ 'connected', 'address', ]),
    ...mapGetters('auth', [ 'loggedIn' ]),
    ...mapState('auth', { 'authUser': 'user' }),
    ...mapState('tidal', ['track', 'playing']),
    ...mapState('album', ['album', 'nowPlayingAlbum', 'isPlaying']),
    ...mapActions('library', ['removeFromLibrary']),

    showSpotifyButton(){
      if(!this.album.spotify_id){
        return false
      }

      return true
    },
    showAppleMusicButton(){
      return this.album.apple_music_id !== null;
      /*if(this.album.apple_music_id[this.album.locale]){
        return true
      }else{
        return false
      }*/
      //return this.album.apple_music_id.filter(id => id.locale === this.album.locale).length > 0
    },
    showTidalButton(){
      if(!this.album.tidal_id){
        return false
      }
      return true
    },
    appleMusicConnected(){
      return this.$store.getters['musickit/connected']
    },
    tidalConnected(){
      return this.$store.getters['tidal/connected']
    },
    spotifyConnected(){
      return this.$store.getters['spotify/connected']
    },
    serviceConnected(){
      return this.appleMusicConnected || this.tidalConnected || this.spotifyConnected
    },
    shouldShowConnectModal(){
      return this.album && !this.album.is_nft && !this.serviceConnected
    }
  },
  methods: {
    async getUser(){
      this.loading = true;

      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/listener`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.auth.token
          }
        })

        const user = response.data
        this.$store.commit('auth/SET_USER', user)
        this.$store.dispatch('collection/getUserCollection')

        if (this.$store.state.web3.cachedProvider) {
          await this.connectToCachedProvider()
        }

      } catch(error) {
        console.log(error)
        this.errors = error.response.data.message
        /*window.localStorage.removeItem("token")
        window.localStorage.removeItem("user")
        this.$store.commit('auth/DELETE_TOKEN')
        this.$store.commit('auth/DELETE_USER')
        this.$store.commit('collection/SET_COLLECTION', [])
        this.$store.dispatch('web3/disconnect')*/
      }
    },
    hideModals() {
      this.showConnectModal = false
      this.showSignUpLoginModal = false
      this.showLogoutModal = false
      this.showOpenModal = false
      this.showYourCollectionModal = false
      this.showCollectionModal = false
      this.showLinkCopiedModal = false
      this.showRemoveModal = false
      this.showAddedModal = false
      this.showDeleteAccountModal = false
      this.showConnectedModal = false
      this.showWalletModal = false
    },
    authAppleMusic() {
      this.showConnectModal = false
      let instance = MusicKit.getInstance()
      if(this.$store.state.musickit.isAuthorized){
        if (window.confirm("Unauthorize Apple Music?")) {
          instance.unauthorize()
          //this.$store.dispatch('services/setNoService')
        }
      }else{
        instance.authorize()
      }
    },
    authSpotify() {
      if(this.spotifyConnected){
        if (window.confirm("Unauthorize Spotify?")) {
          this.$store.dispatch('spotify/logout')
        }
      }else{
        this.showConnectModal = false
        //this.$store.dispatch('services/setLoading', true)
        this.$store.dispatch('spotify/login')
      }
    },
    authTidal() {
      this.showConnectModal = false
      if(this.tidalConnected){
        if (window.confirm("Unauthorize TIDAL?")) {
          this.$store.dispatch('tidal/logout')
        }
      }else{
        this.$store.dispatch('tidal/login')
      }
    },
    playClicked() {
      this.hideModals()

      if(this.album.is_nft || this.serviceConnected){
        play(this.album)
        return
      }

      this.showConnectModal = true
    },
    pauseClicked() {

      if(this.nowPlayingAlbum.is_nft){
        pause()
        return
      }

      if(this.serviceConnected){
        pause()
        return
      }
    },
    resumeClicked() {
      this.hideModals()

      if(this.$store.getters['album/getNowPlayingAlbum']){
        resume()
      }

    },
    copyLink(){
      navigator.clipboard.writeText(window.location.href)
      this.hideModals()
      this.showLinkCopiedModal = true
      setTimeout(() => {
        this.showLinkCopiedModal = false
      }, 1500)
    },
    remove(){
      this.loading = true
      this.$store.dispatch('collection/removeFromCollection', this.album.id).then(() => {

      }).catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false
        this.showRemoveModal = false
      })
    },
    serviceHasBeenConnected(){
      this.hideModals()
      this.showConnectedModal = true
      setTimeout(() => {
        this.showConnectedModal = false
      }, 3000)
    },
    connectWallet(){
      this.showWalletModal = true
    },
    clearSearch(){
      this.$refs.routerView.clearSearch()
    },
    async connectToCachedProvider(){
      this.showModal = false
      if(this.$store.state.web3.cachedProvider.toLowerCase() === 'injected'){
        await this.connectMetamask()
      }else if(this.$store.state.web3.cachedProvider.toLowerCase() === 'walletconnect'){
        await this.connectWalletConnect()
      }
    },
    async connectMetamask(){
      this.showWalletModal = false
      await this.$store.dispatch('web3/connectInjected')
    },
    async connectWalletConnect(){
      this.showWalletModal = false
      await this.$store.dispatch('web3/connectWalletConnect')
    },
    async dissociateWallet(){
      axios.post(`${process.env.VUE_APP_API_URL}/listener/update`, {
        wallet: null
      }, {
        headers: {
          Authorization: "Bearer " + this.$store.state.auth.token
        }
      })
      .then((response) => {
        console.log(response)
        //this.logout()
        const user = response.data.user
        this.$store.commit('auth/SET_USER', user)
        this.$store.dispatch('web3/disconnect')
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
      })

    },
    walletConnected(){

      //if wallet exists in db then log listener in - be
      //if wallet does not exist then create listener -be
      //if listener is logged in then assign wallet to them - fe
      if(this.loggedIn){
        if(this.authUser.wallet !== this.address){
          axios.post(`${process.env.VUE_APP_API_URL}/listener/update`, {
            wallet: this.address
          }, {
            headers: {
              Authorization: "Bearer " + this.$store.state.auth.token
            }
          })
          .then((response) => {
            console.log(response)
            const user = response.data.user
            this.$store.commit('auth/SET_USER', user)
            //this.$store.dispatch('collection/getUserCollection')
            let route = this.$route.query.redirect || { name: 'usercollection', params: { username: user.username || user.wallet } }
            this.$router.push(route)
          })
          .catch((error) => {
            console.log(error)
            this.errors = error.response.data.message
          })
          .finally(() => {
            this.updatingUsername = false
          })
        }
      }else if(!this.loggedIn){
        axios.post(`${process.env.VUE_APP_API_URL}/listener/connect`, {
          wallet: this.address
        })
        .then((response) => {
          console.log(response)
          const token = response.data.token
          const user = response.data.user
          this.$store.commit('auth/SET_TOKEN', token)
          this.$store.commit('auth/SET_USER', user)
           this.$store.dispatch('collection/getUserCollection')
          let route = this.$route.query.redirect || { name: 'usercollection', params: { username: user.username || user.wallet } }
          this.$router.push(route)
        })
        .catch((error) => {
          this.errors = error.response.data.message
        })
        .finally(() => {
          this.loading = false
        })
      }else{
        this.logout()
      }
    },
    copyWalletAddress(){
      navigator.clipboard.writeText(this.authUser.wallet)
      this.hideModals()
      this.showAddressCopiedModal = true
      setTimeout(() => {
        this.showAddressCopiedModal = false
      }, 1500)
    },
    logout(){
      window.localStorage.removeItem("token")
      window.localStorage.removeItem("user")
      this.$store.commit('auth/DELETE_TOKEN')
      this.$store.commit('auth/DELETE_USER')
      this.$store.commit('collection/SET_COLLECTION', [])
      this.$store.dispatch('web3/disconnect')
      this.$router.replace({ name: 'home' })
    },
    checkWindowFocused(){
      if (document.hasFocus()) {
        if(!this.windowHasFocus){
          if(this.isPlaying){
            if(this.$store.getters['spotify/connected']){
              if(!this.nowPlayingAlbum.is_nft){
                this.$store.dispatch('spotify/transferUsersPlayback')
              }
            }
          }
        }
        this.windowHasFocus = true
      } else {
        this.windowHasFocus = false
      }
    }
  },
  mounted(){
    if(this.loggedIn){
      this.getUser()
    }

    if(this.$store.getters['spotify/connected']){
      this.$store.dispatch('spotify/initPlayer')
    }

    setInterval(this.checkWindowFocused, 1000)
    //this.$store.dispatch('spotify/refreshToken')
  },
  created(){
    console.log('mounted')

  }
};
</script>

<style>
/*html, body{
  padding-bottom: env(safe-area-inset-bottom);
}

body.home{
  height: 100%;
  overflow: auto;
}
body.album{
  height: 100%;
  overflow: auto;
}*/

body {
-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

input:-webkit-autofill {
  background: none;
}
input:autofill {
  background: none;
}

body.landscape{
  height: 100%;
  overflow: hidden;
}

</style>