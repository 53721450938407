<template>
    <div class="mr-2 truncate ... hidden md:inline" v-if="owner">
      (owned by <a class="cursor-pointer border-b-2 border-white hover:border-transparent inline-block align-middle max-w-10 truncate" :href="`https://beta.catalog.works/id/${owner.id}`" target="_blank">@{{ handle ? handle : owner.id }}</a> on <a class="cursor-pointer border-b-2 border-white hover:border-transparent max-w-16" :href="nft.url" target="_blank">{{ nft.platform }}</a>)
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "NowPlayingNft",
  computed: {
    ...mapState('album', ['owner', 'handle', 'nft'])
  }
};
</script>