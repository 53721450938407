<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 52 32"
    enable-background="new 0 0 0 0"
    xml:space="preserve"
    class="text-black dark:text-white"
  >
    <circle fill="currentColor" stroke="none" cx="6" cy="16" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.1"
      />
    </circle>
    <circle fill="currentColor" stroke="none" cx="26" cy="16" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.2"
      />
    </circle>
    <circle fill="currentColor" stroke="none" cx="46" cy="16" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.3"
      />
    </circle>
  </svg>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style scoped>
svg {
  width: 100%;
  height: 100%;
  display: inline-block;
}
</style>
