<template>
  <div class="tracklist select-none w-full h-full px-2 md:px-4" :style="backCoverStyle">
    <div v-if="portrait" class="p-4">
      <div v-if="!album.is_nft" class="line-clamp-2 mb-4">
        <div
          @click="playTrack(album.tracks[0], 0)"
          class="inline max-w-full opacity-100 hover:opacity-50 truncate mr-2 cursor-pointer">
          <svg class="inline w-4 h-4 mb-1"
            version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px"
            height="20px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
            <g fill="currentColor">
              <polygon points="1.5,1.006 1.5,18.993 18.5,10" />
            </g>
          </svg> {{ album.artist }} - {{ album.title }} ({{ album.year }})
        </div>
      </div>
      <div v-for="(track, index) in album.tracks" :key="index"
        :class="{ 'mt-4': index > 0 && album.tracks[index - 1].disc < track.disc }">
        <div v-if="album.is_nft" class="mb-8">
          {{ album.artist }}
        </div>
        <div
          @click="playTrack(track, index)"
          class="inline max-w-full opacity-100 hover:opacity-50 truncate mr-2 cursor-pointer">
          <svg v-if="album.is_nft" class="inline w-4 h-4 mb-1"
            version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px"
            height="20px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
            <g fill="currentColor">
              <polygon points="1.5,1.006 1.5,18.993 18.5,10" />
            </g>
          </svg> {{ track.number }}. {{ track.name.trim() }}</div>
        <span
          v-if="album.is_nft"
          class="text-sm flex-shrink-0 gradient-bg">NFT</span>
      </div>
      <div v-if="album.is_nft" >
        <NFTInfo :album="album" />
      </div>
    </div>
    <div
      v-else
      class="w-full h-full flex flex-col justify-between">
        <div class="w-full h-10 z-10 flex justify-center items-start">
          <div
            v-if="pages.length > 0 && currentPage > 0"
            @click="prev"
            class="mt-2 -mb-2 transform rotate-180 cursor-pointer hover:opacity-80">
            <svg class="h-6" viewBox="0 0 48 22">
              <g>
                <path style="opacity:0.49;fill:#7F7F7F;" d="M44,22H4c-2.21,0-4-1.79-4-4V4c0-2.21,1.79-4,4-4h40c2.21,0,4,1.79,4,4v14C48,20.21,46.21,22,44,22z"/>
                <polyline style="fill:none;stroke:currentColor;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="34.5,6.25 24,16.75 13.5,6.25 	"/>
              </g>
            </svg>
          </div>
        </div>
        <div v-if="!album.is_nft" class="line-clamp-2 pb-4">
          <div
            @click="playTrack(album.tracks[0], 0)"
            class="inline max-w-full opacity-100 hover:opacity-50 truncate mr-2 cursor-pointer">
            <svg class="inline w-4 h-4 mb-1"
              version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px"
              height="20px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
              <g fill="currentColor">
                <polygon points="1.5,1.006 1.5,18.993 18.5,10" />
              </g>
            </svg> {{ album.artist }} - {{ album.title }} ({{ album.year }})
          </div>
        </div>
        <div ref="tracks" class="w-full h-full overflow-hidden mt-4">
          <div
            v-for="(page, index) in pages" :key="index"
            class="page h-full transition ease-in-out duration-200"
            :style="translate">
            <div v-if="album.is_nft" class="mb-8">
              {{ album.artist }}
            </div>
            <div
              v-for="(track, index) in page" :key="index"
              class="track"
              :class="{ 'mt-4': index > 0 && page[index - 1].disc < track.disc }">
              <div
                @click="playTrack(track, index)"
                class="inline max-w-full opacity-100 hover:opacity-50 truncate mr-2 cursor-pointer">
                <svg v-if="album.is_nft" class="inline w-4 h-4 mb-1"
                  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px"
                  height="20px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
                  <g fill="currentColor">
                    <polygon points="1.5,1.006 1.5,18.993 18.5,10" />
                  </g>
                </svg> {{ track.number }}. {{ track.name.trim() }}
              </div>
              <span
                v-if="album.is_nft"
                class="text-sm flex-shrink-0 gradient-bg">NFT</span>
            </div>
            <div v-if="album.is_nft" >
              <NFTInfo :album="album" />
            </div>
          </div>
        </div>
        <div class="w-full h-10 z-10 flex justify-center items-end">
          <div
            class="mb-2 cursor-pointer hover:opacity-80"
            @click="next"
            v-if="pages.length > 0 && currentPage < pages.length - 1">
            <svg class="h-6" viewBox="0 0 48 22">
              <g>
                <path style="opacity:0.49;fill:#7F7F7F;" d="M44,22H4c-2.21,0-4-1.79-4-4V4c0-2.21,1.79-4,4-4h40c2.21,0,4,1.79,4,4v14C48,20.21,46.21,22,44,22z"/>
                <polyline style="fill:none;stroke:currentColor;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="34.5,6.25 24,16.75 13.5,6.25 	"/>
              </g>
            </svg>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
import NFTInfo from '@/components/NFTInfo'

export default {
  name: 'AlbumTracklist',
  components: {
    NFTInfo
  },
  props: {
    album: Object,
    portrait: Boolean
  },
  data(){
    return {
      currentPage: 0,
      translation: 0,
      numTracksPerPage: 16,
    }
  },
  computed: {
    backCoverStyle(){
      return {
        'background-color': this.album.bg_color ? `#${this.album.bg_color}` : '#fff',
        'color': this.album.fg_color ? `#${this.album.fg_color}` : '#000',
      }
    },
    pages(){
      return this.chunkArrayInGroups(this.album.tracks, this.numTracksPerPage)
    },
    translate(){
      return {
        'transform': `translate(0,${this.translation}px)`
      }
    }
  },
  mounted(){
    this.paginateTracks()
    window.addEventListener('resize', this.windowResized)
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.windowResized)
  },
  methods: {
    prev(){
      this.currentPage--;
      var elem = this.$refs.tracks
      if(elem){
        this.translation = -(elem.getBoundingClientRect().height * this.currentPage)
      }

    },
    next(){
      this.currentPage++;
      var elem = this.$refs.tracks
      if(elem){
        this.translation = -(elem.getBoundingClientRect().height * this.currentPage)
      }
    },
    windowResized(){
      if(!this.portrait){
        this.paginateTracks();
      }
    },
    paginateTracks(){
      if(!this.$refs.tracks)return

      let track = document.querySelector('.track')
      let trackHeight = track ? track.offsetHeight : 24
      let numTracks = Math.floor(this.$refs.tracks.clientHeight / trackHeight)
      this.numTracksPerPage = numTracks
    },
    chunkArrayInGroups(arr, size) {
      var myArray = [];
      for(var i = 0; i < arr.length; i += size) {
        myArray.push(arr.slice(i, i+size));
      }
      return myArray;
    },
    playTrack(track, index){
      this.$root.$emit('play-track', track, index)
    },
  },
}
</script>

<style scoped>
.track {
  @apply text-base leading-8 xl:text-lg xl:leading-10;
}

@media (orientation: landscape) {
  .tracklist {
    flex-shrink: 0;
    width: calc(100vh - 5rem);
    height: calc(100vh - 5rem);
  }
}

@media (orientation: portrait) {
  .tracklist {
    flex-shrink: 0;
    width: 100vw;/*calc(100vw - 5rem);*/
    min-height: 100vw;
  }
}
</style>