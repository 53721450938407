<template>
  <div class="m-6">

    <button class="mr-8 rounded border border-white px-4 py-2" @click="tidalBtnClicked">
      <span v-if="tidalConnected">Disconnect TIDAL</span>
      <span v-else>Connect TIDAL</span>
    </button>

    <button class="mr-8 rounded border border-white px-4 py-2" @click="refreshToken">Refresh Token</button>
    <button class="mr-8 rounded border border-white px-4 py-2" @click="session">Get Session</button>
    <button class="mr-8 rounded border border-white px-4 py-2" @click="search">Test Search</button>
    <button class="mr-8 rounded border border-white px-4 py-2" @click="play">
      <span v-if="playing">Pause</span>
      <span v-else>Play</span>
    </button>
    <button class="mr-8 rounded border border-white px-4 py-2" @click="getAlbum">Get Album</button>
    <div class="mt-6" v-if="$store.getters['tidal/connected']">
      <p>TIDAL <span>Connected</span></p>
    </div>

    <div class="mt-6 hidden">
      <div v-for="album in albums" :key="album.apple_music_id" class="my-4">
        {{ album.title }} by {{ album.artist }}
        <button class="mr-8 rounded border border-white px-4 py-2" @click="playpre(album)">Play pre-paywall</button>
        <button class="mr-8 rounded border border-white px-4 py-2" @click="playpost(album)">Play post-paywall</button>
      </div>
    </div>

    <audio ref="tidalPlayer" controls autoplay :src="track"></audio>

    <div class="mt-6" v-if="nowPlaying.title">
      Now playing: {{ nowPlaying.title }} by {{ nowPlaying.artist }}
    </div>

    <hr class="my-4" />
    <pre>{{ $store.state.tidal }}</pre>

  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
export default {
  name: 'Tidal',
  data(){
    return {
      albums: [
        {
          apple_music_id: '1542588729',
          spotify_id: 'spotify:album:0JZkzjywipWhUbdYPCEaMo',
          tidal_id: '',
          artist: 'The Black Keys',
          title: 'Brothers'
        },
        {
          apple_music_id: '286773545',
          spotify_id: 'spotify:album:0nLCUby65vPnn29qRPFq4I',
          tidal_id: '',
          artist: 'Nightmares On Wax',
          title: 'Thought So...'
        }
      ],
      playing: false
    }
  },
  computed: {
    ...mapState('tidal', {
      track: 'track'
    }),
    tidalConnected(){
      return this.$store.getters['tidal/connected']
    },
    nowPlaying(){
      let nowPlaying = {
        artist: '',
        title: ''
      }
      if(this.spotifyConnected){
        if(this.$store.state.spotify.playback){
          nowPlaying.artist = this.$store.state.spotify.playback.artists[0].name || '',
          nowPlaying.title = this.$store.state.spotify.playback.name || ''
        }
      }
      return nowPlaying
    }
  },
  mounted(){
    this.$refs.tidalPlayer.addEventListener('timeupdate', this.timeUpdate)
    this.$refs.tidalPlayer.addEventListener('ended', this.trackEnded)
    this.$refs.tidalPlayer.addEventListener('play', this.trackPlayed)
    this.$refs.tidalPlayer.addEventListener('pause', this.trackPaused)

    let tidalId = '72694579'
    this.$store.dispatch('tidal/getAlbumTracks', { album_id: tidalId })
  },
  methods: {
    timeUpdate(){
      let duration = this.$refs.tidalPlayer.duration
      let time = this.$refs.tidalPlayer.currentTime
      //console.log(duration, time)
      if(duration - time < 10){
        if(!this.$store.state.tidal.nextTrack && !this.$store.state.tidal.busy){
          console.log('10 seconds left - get next track')
          this.$store.dispatch('tidal/getNextTrack')
        }
      }
    },
    trackEnded(){
      console.log('track ended', this.$store.state.tidal.nextTrack)
      if(this.$store.state.tidal.nextTrack){
        console.log('play next')
        this.$store.dispatch('tidal/playNextTrack')
      }
    },
    trackPlayed(){
      this.playing = !this.$refs.tidalPlayer.paused
    },
    trackPaused(){
      this.playing = !this.$refs.tidalPlayer.paused
    },
    refreshToken(){
      this.$store.dispatch('tidal/refreshToken')
    },
    tidalBtnClicked(){
      if(this.tidalConnected){
        this.$store.dispatch('tidal/logout')
      }else{
        this.$store.dispatch('tidal/login')
      }
    },
    search(){
      this.$store.dispatch('tidal/search')
    },
    session(){
      this.$store.dispatch('tidal/getSession')
    },
    play(){
      //if no track then load it
      if(!this.$store.state.tidal.track){
        this.$store.dispatch('tidal/loadAlbum')

      }else{
        if(this.$refs.tidalPlayer.paused){
          this.$refs.tidalPlayer.play()
        }else{
          this.$refs.tidalPlayer.pause()
        }

      }
    },
    getAlbum(){
      this.$store.dispatch('tidal/getAlbum')
    }
  }
}
</script>