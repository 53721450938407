export function getPosition(e, context) {
  let rect = context.canvas.getBoundingClientRect();
  let x = mapRange(e.clientX - rect.left, 0, rect.width, 0, 400);
  let y = mapRange(e.clientY - rect.top, 0, rect.height, 0, 400);
  return { x: x, y: y };
}

export function dist(x1, y1, x2, y2) {
  let a = x1 - x2;
  let b = y1 - y2;

  return Math.sqrt(a * a + b * b);
}

export function rotate(cx, cy, x, y, angle) {
    var radians = (Math.PI / 180) * angle,
        cos = Math.cos(radians),
        sin = Math.sin(radians),
        nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
        ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
    return { x: nx, y: ny };
}

export function mapRange(num, in_min, in_max, out_min, out_max) {
  return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}

export function normalizePoints(points, size) {
  return points.map(function (v) {
    return {
      x: v.x / size,
      y: v.y / size,
    };
  });
}
