const drawModes = {
  NONE: "NONE",
  DRAW: {
    RECT: "RECT",
    POLY: "POLY",
  },
  ERASE: "ERASE",
};

const CANVAS_SIZE = 400;
const HANDLE_SIZE = 10;
const MIN_ARTWORK_SIZE = 1200;
const MAX_ARTWORK_SIZE = 4200;
const MAX_FILE_SIZE = 10000000;

export default {
  CANVAS_SIZE: CANVAS_SIZE,
  HANDLE_SIZE: HANDLE_SIZE,
  MIN_ARTWORK_SIZE: MIN_ARTWORK_SIZE,
  MAX_ARTWORK_SIZE: MAX_ARTWORK_SIZE,
  MAX_FILE_SIZE: MAX_FILE_SIZE,
  DRAW_MODE: drawModes.DRAW.RECT
};
