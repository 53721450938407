import store from '@/store'

let player = new Audio()
player.addEventListener('loadeddata', () => {});
player.addEventListener('canplay', () => {
    player.play()
});
player.addEventListener('playing', () => {
    store.commit('album/IS_PLAYING', true)
});
player.addEventListener('pause', () => {
    store.commit('album/IS_PLAYING', false)
});
player.addEventListener('timeupdate', () => {
});
player.addEventListener('ended', () => {
    store.commit('album/IS_PLAYING', false)
});

const pauseCurrent = (album) => {
    if(store.state.album.isPlaying){
        if(store.getters['album/getNowPlayingAlbum'].is_nft && !album.is_nft){
            player.pause()
            player.src = null
        }

        else if(store.getters['spotify/connected'] && album.is_nft){
            store.dispatch('spotify/pause')
        }

        else if(store.getters['musickit/connected'] && album.is_nft){
            store.dispatch('musickit/pause')
        }

        else if(store.getters['tidal/connected'] && album.is_nft){
            store.dispatch('tidal/setIsPlaying', false)
        }
    }
}

export function play(album) {

    pauseCurrent(album)

    if(album.is_nft){
        player.src = album.tracks[0].media_url
        player.load()
        store.dispatch('album/setNowPlayingAlbum', album)
        return
    }

    if(store.getters['spotify/connected']){

        if(!store.state.spotify.ready) return

        if(!album.spotify_id){
            alert('Album unavailable with Spotify')
            return
        }

        let payload = {
            context_uri: `spotify:album:${album.spotify_id}`,
            offset: { 'position': 0 }
        }

        store.dispatch('spotify/play', payload)
        store.dispatch('album/setNowPlayingAlbum', album)
        return

    }

    if(store.getters['musickit/connected']){

        let payload = {
        id: album.apple_music_id,
        index: 0
        }
        store.dispatch('musickit/play', payload)
        store.dispatch('album/setNowPlayingAlbum', album)
        return

    }

    if(store.getters['tidal/connected']){

        if(!album.tidal_id){
            alert('Album unavailable with TIDAL')
            return
        }

        let payload = {
            offset: 0
        }
        store.dispatch('tidal/loadAlbum', payload)
        store.dispatch('album/setNowPlayingAlbum', album)
        return
    }
}

export function playTrack(album, trackIndex) {

    pauseCurrent(album)

    if(album.is_nft){
        player.src = album.tracks[trackIndex].media_url
        player.load()
        store.dispatch('album/setNowPlayingAlbum', album)
        store.commit('album/IS_PLAYING', true)
        return
    }

    if(store.getters['spotify/connected']){

        if(!store.state.spotify.ready) return

        if(!album.spotify_id){
            alert('Album unavailable with Spotify')
            return
        }

        let payload = {
            album: album,
            context_uri: `spotify:album:${album.spotify_id}`,
            offset: { 'position': trackIndex }
        }
        store.dispatch('spotify/play', payload)
        store.dispatch('album/setNowPlayingAlbum', album)
        return
    }

    else if(store.getters['musickit/connected']){

        let payload = {
            id: album.apple_music_id,
            index: trackIndex
        }
        store.dispatch('musickit/play', payload)
        store.dispatch('album/setNowPlayingAlbum', album)
        return
    }

    else if(store.getters['tidal/connected']){

        if(!album.tidal_id){
            alert('Album unavailable with TIDAL')
            return
        }

        let payload = {
            offset: trackIndex
        }
        store.dispatch('tidal/loadAlbum', payload)
        store.dispatch('album/setNowPlayingAlbum', album)
        return
    }
}

export function pause() {

    if(store.getters['album/getNowPlayingAlbum'].is_nft){
        player.pause()
        return
    }

    if(store.getters['spotify/connected']){

        store.dispatch('spotify/pause')
        return

    }

    if(store.getters['musickit/connected']){

        store.dispatch('musickit/pause')
        return

    }

    if(store.getters['tidal/connected']){

        store.dispatch('tidal/setIsPlaying', false)
        return
    }
}

export function resume() {

    if(store.getters['album/getNowPlayingAlbum'].is_nft){
        player.play()
        return
    }

    if(store.getters['spotify/connected']){

        store.dispatch('spotify/resume', null)
        return

    }

    if(store.getters['musickit/connected']){

        store.dispatch('musickit/play', null)
        return

    }

    if(store.getters['tidal/connected']){

        store.dispatch('tidal/setIsPlaying', true)
        return
    }
}