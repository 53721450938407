<template>
  <div id="canvas-container" class="canvas-container">
    <canvas
      id="canvas"
      @click="click"
      @mousedown="mousedown"
      @mousemove="mousemove"
      @mouseup="mouseup"
      @mouseleave="mouseleave"
      @mouseenter="mouseenter"
      @touchstart="touchstart"
      @touchmove="touchmove"
      @touchend="touchend"
      @touchleave="touchend"
    ></canvas>
  </div>
</template>

<script>
import { Rectangle, Polygon } from "@/polygon.js";
import { getPosition } from "@/helpers.js";
import CONSTANTS from "@/constants";

export default {
  name: "Canvas",
  props: {
    album: null,
    polygons: {
      type: Array
    }
  },
  data() {
    return {
      CONSTANTS,
      Polygon,
      Rectangle,
      context: null,
      activePoly: -1,
      bMouseDown: false,
      bInsidePolygon: false,
      mousePos: null,
      touchPos: null,
      bIsEnabled: true,
      polyInside: -1,
      canvas: null
    };
  },
  mounted() {
    this.canvas = document.getElementById("canvas");
    this.canvas.width = CONSTANTS.CANVAS_SIZE;
    this.canvas.height = CONSTANTS.CANVAS_SIZE;
    this.context = this.canvas.getContext("2d");
    this.startAnimating(30);
    this.show();
  },
  methods: {
    setPosAndSize(l, t, w, h) {
      let container = document.getElementById("canvas-container");
      container.style.left = l;
      container.style.top = t;

      this.setSize(w, h);
    },
    setSize(w, h) {
      console.log(w + " " + h);
    },
    show() {
      let container = document.getElementById("canvas-container");
      container.style.display = "block";

      let canvas = document.getElementById("canvas");
      canvas.style.display = "block";
    },
    hide() {
      let container = document.getElementById("canvas-container");
      container.style.display = "none";

      let canvas = document.getElementById("canvas");
      canvas.style.display = "none";
    },
    enable() {
      this.bIsEnabled = true;
    },
    disable() {
      this.bIsEnabled = false;
    },
    windowResized() {
      //let container = document.getElementById("canvas-container");
      //let canvas = document.getElementById("canvas");
      //canvas.width = container.parentElement.clientWidth;
      //canvas.height = container.parentElement.clientWidth;
      //canvas.style.width = container.parentElement.clientWidth + "px";
      //canvas.style.height = container.parentElement.clientHeight + "px";
      //console.log(canvas.width);
    },
    insidePolygon(pos) {
      let i = -1;
      this.polygons.forEach(function(poly, index) {
        if (poly.insidePolygon(pos)) {
          poly.bInsidePolygon = true;
          i = index;
        } else {
          poly.bInsidePolygon = false;
        }
      });
      return i;
    },
    getTouchPos(canvasDom, touchEvent) {
      var rect = canvasDom.getBoundingClientRect();
      return {
        x: touchEvent.touches[0].clientX - rect.left,
        y: touchEvent.touches[0].clientY - rect.top
      };
    },
    touchstart(e) {
      /*e.preventDefault()
      var touch = e.touches[0];
      this.touchPos = {
        clientX: touch.clientX,
        clientY: touch.clientY
      };
      var mouseEvent = new MouseEvent("mousedown", this.touchPos);
      this.canvas.dispatchEvent(mouseEvent);*/
    },
    touchend(e) {
      /*e.preventDefault()
      var touch = e.touches[0];
      var mouseEvent = new MouseEvent("mouseup", this.touchPos);
      this.canvas.dispatchEvent(mouseEvent);*/
    },
    touchmove(e) {
      /*e.preventDefault()
      var touch = e.touches[0];
      this.touchPos = {
        clientX: touch.clientX,
        clientY: touch.clientY
      };
      var mouseEvent = new MouseEvent("mousemove", this.touchPos);
      this.canvas.dispatchEvent(mouseEvent);*/
    },
    mousedown(e) {
      /*if (!this.bIsEnabled) return;
      this.bMouseDown = true;

      let pos = getPosition(e, this.context);
      this.startPos = pos;

      this.bInsidePolygon = false;

      //check whether we have hit inside a polygon
      this.polyInside = this.insidePolygon(pos);

      if (this.polyInside > -1) {
        this.bInsidePolygon = true;
        e.preventDefault();
      }*/
    },
    mousemove(e) {
      if (!this.bIsEnabled) return

      let pos = getPosition(e, this.context)

      this.mousePos = pos
      //check whether we have hit inside a polygon
      this.polyInside = this.insidePolygon(pos)


      if (this.polyInside === -1) {
        if(this.cursorIsPointer){
          this.cursorIsPointer = false
          this.canvas.style.cursor = 'default'
        }
        this.$emit('track-hover-out')

        this.bInsidePolygon = false
      }else{
        if(!this.cursorIsPointer){
          this.cursorIsPointer = true
          this.canvas.style.cursor = 'pointer'
        }
        this.$emit('track-hover', this.polyInside)
        e.preventDefault()
      }

    },
    mouseup(e) {
      /*if (!this.bIsEnabled) return;

      this.bMouseDown = false;

      let pos = getPosition(e, this.context);

      if (this.bInsidePolygon === true) {
        let polyInside = this.insidePolygon(pos);
        let p = this.polygons[polyInside];
        this.$root.$emit('play-track', p.track, polyInside);
      }

      this.polygons.forEach(function(poly) {
        poly.bIsMouseOver = false;
      });

      e.preventDefault();*/
    },
    mouseenter() {},
    mouseleave() {
      /*this.polygons.forEach(function(poly) {
        poly.bIsMouseOver = false;
      });*/
    },
    click(e){
      if (!this.bIsEnabled) return

      this.bMouseDown = false

      let pos = getPosition(e, this.context)

      let polyInside = this.insidePolygon(pos)
      if(polyInside > -1){
        let p = this.polygons[polyInside]
        this.$root.$emit('play-track', p.track, polyInside)
      }


      this.polygons.forEach(function(poly) {
        poly.bIsMouseOver = false
      })

      e.preventDefault()
    },
    startAnimating(fps) {
      this.fpsInterval = 1000 / fps;
      this.prevTime = Date.now();
      this.animate();
    },
    animate() {
      // request another frame
      requestAnimationFrame(this.animate);

      // calc elapsed time since last loop
      let now = Date.now();
      let elapsed = now - this.prevTime;

      // if enough time has elapsed, draw the next frame
      if (elapsed > this.fpsInterval) {
        // Get ready for next frame by setting then=now, but also adjust for your
        // specified fpsInterval not being a multiple of RAF's interval (16.7ms)
        this.prevTime = now - (elapsed % this.fpsInterval);

        this.draw();
      }
    },
    draw() {
      let self = this;

      //Clear the canvas
      this.context.clearRect(0,0,this.context.canvas.width,this.context.canvas.height);

      //self.context.lineJoin = "round";
      self.context.lineWidth = 1;

      if (this.polyInside > -1) {
        if(this.bIsMouseDown){
          this.polygons[this.polyInside].draw(self.context, true);
        }else{
          this.polygons[this.polyInside].draw(self.context, false);
        }
      }

    },
    keydown() {
    },
    keyup() {
    },
  },
};
</script>

<style scoped>
#canvas{
  width: 100%;
  height: 100%;
}

.canvas-container {
  position: absolute;
  background: rgb(255, 0, 0, 0);
  top: 0;
  left: 0;
}
@media (orientation: landscape) {
  .canvas-container{
    width: calc(100vh - 5rem);
    height: calc(100vh - 5rem);
  }
}

@media (orientation: portrait) {
  .canvas-container{
    width: 100vw; /*calc(100vw - 5rem);*/
    height: 100vw; /*calc(100vw - 5rem);*/
  }
}
/*.pointer {
  cursor: url("../assets/png/pointer.png") 11 11, auto;
}*/
</style>
