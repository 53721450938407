<template>
  <div  @click="thumbnailClicked"
        class="mr-2 w-6 h-6 cursor-pointer">
    <img :src="cover" />
  </div>
</template>

<script>
export default {
  name: 'NowPlayingThumbnail',
  props: {
    cover: {
      type: String
    },
    id: {
      type: String
    }
  },
  methods: {
    thumbnailClicked(){
      //if(this.$route.name !== 'album' && ){
        this.$router.push({ name: 'release', params: { id: this.id } })
      //}
    }
  }
};
</script>